import { createSelector } from '@reduxjs/toolkit';
import { DismissableContentName, QuickActionName } from '../../constants/app';
import { OperatingSystemFamily } from '../../constants/browser';
import { FeatureFlagName } from '../../constants/features';
import { DefaultScheduleName } from '../../constants/schedule';
import {
    CommonSkipDelayDevBoxActionErrorCode,
    DelayDevBoxActionErrorCode,
    DevBoxActionActionType,
} from '../../data/contracts/dev-box-action';
import { ScheduleContract } from '../../data/contracts/schedule';
import { getTokensFromDevBoxDataPlaneUri } from '../../ids/dev-box';
import { createPoolDataPlaneUri } from '../../ids/pool';
import { createProjectDataPlaneUri } from '../../ids/project';
import { createScheduleDataPlaneUri } from '../../ids/schedule';
import { Failure, Status } from '../../models/common';
import { CustomizationFailure, CustomizationGroup } from '../../models/customization';
import {
    DevBox,
    DevBoxOperation,
    DevBoxRepairOperation,
    DevBoxSnapshot,
    isDevBoxRepairOperation,
} from '../../models/dev-box';
import { DevBoxAction, DevBoxToDevBoxActionMap } from '../../models/dev-box-action';
import { ScheduleMap } from '../../models/schedule';
import { getDismissedMessages } from '../../redux/selector/application-selectors';
import { StoreStateSelector, isStatusSuccessful, isStatusTerminal } from '../../redux/selector/common';
import {
    getCustomizationGroupFailuresByDevBox,
    getCustomizationGroupsByDevBox,
} from '../../redux/selector/customization-selectors';
import {
    getDevBoxActionsGroupedByDevBoxId,
    getStatusesForDelayAllDevBoxActionsByDevBoxId,
    getStatusesForDelayDevBoxActionGroupedByDevBoxId,
    getStatusesForListDevBoxActionsByDevBoxId,
    getStatusesForSkipAllDevBoxActionsByDevBoxId,
    getStatusesForSkipDevBoxActionGroupedByDevBoxId,
} from '../../redux/selector/dev-box-action-selectors';
import {
    getDevBoxOperationsByDevBox,
    getStatusesForListDevBoxOperations,
} from '../../redux/selector/dev-box-operation-selectors';
import {
    getDevBoxes,
    getFailuresOnDevBoxesById,
    getPendingStatesForDevBoxesById,
} from '../../redux/selector/dev-box-selectors';
import { getPools } from '../../redux/selector/pool-selectors';
import { getProjectsByDiscoveryServiceURI } from '../../redux/selector/project-from-discovery-service-selectors';
import { getProjectsByDataPlaneId } from '../../redux/selector/project-selectors';
import {
    getRemoteConnectionsByDevBoxId,
    getStatusesForGetRemoteConnectionByDevBoxId,
} from '../../redux/selector/remote-connection-selectors';
import { getSchedules, getStatusesForListSchedulesByProject } from '../../redux/selector/schedule-selectors';
import { getSnapshotsByDevBox } from '../../redux/selector/snapshot-selectors';
import { getStatusForLoadDevBoxCardContent } from '../../redux/selector/sub-applications/home-selectors';
import { getDisplayTime } from '../../redux/selector/time-selectors';
import { AsyncState } from '../../redux/store/common-state';
import { DevBoxState } from '../../redux/store/dev-box-state';
import { SerializableMap } from '../../types/serializable-map';
import { SerializableSet } from '../../types/serializable-set';
import { last, sortByInPlace } from '../../utilities/array';
import { compareDates } from '../../utilities/date';
import { getDevBoxState } from '../../utilities/dev-box';
import {
    isDevBoxScheduleAction,
    isDevBoxScheduleDeleteAction,
    isDevBoxStopOnDisconnectAction,
} from '../../utilities/dev-box-action';
import { isFeatureFlagEnabled } from '../../utilities/features';
import { every, forEach, get, map, set, values } from '../../utilities/serializable-map';
import { has } from '../../utilities/serializable-set';
import { getFailuresFromStatuses } from '../../utilities/status';
import { areStringsEquivalent, compareStrings } from '../../utilities/string';
import {
    addHours,
    getAreDatesLessThanNHoursApart,
    getDateFromTimeString,
    getHoursBetweenDates,
} from '../../utilities/time';
import {
    DevBoxActionState,
    DevBoxActionViewModel,
    DevBoxActionViewModelErrorCode,
    DevBoxActionWithNextScheduledTime,
    DevBoxViewModel,
    isDevBoxActionWithNextScheduledTime,
} from './models';

/** Filter out errors for actions >24 hours from now, or for actions with scheduled times after the requested delayTime. */
const filterToRelevantDelayFailures = (statuses: SerializableMap<Status>) => {
    const failures = getFailuresFromStatuses(values(statuses));

    return failures.filter(
        (failure) =>
            failure.code !== DelayDevBoxActionErrorCode.DevBoxActionPreferenceUpdateNotInAllowedTimeWindow &&
            failure.code !== DelayDevBoxActionErrorCode.InvalidDelayUntilTime
    );
};

/** Filter out errors for actions >24 hours from now */
const filterToRelevantSkipFailures = (statuses: SerializableMap<Status>) => {
    const failures = getFailuresFromStatuses(values(statuses));

    return failures.filter(
        (failure) => failure.code !== DelayDevBoxActionErrorCode.DevBoxActionPreferenceUpdateNotInAllowedTimeWindow
    );
};

const getDelayStateAndErrorCodeForDevBox = (
    id: string,
    statusesOnDevBoxes: SerializableMap<Status>,
    statusesOnDevBoxActions: SerializableMap<SerializableMap<Status>>
): { state: AsyncState; errorCode?: DevBoxActionViewModelErrorCode } =>
    getSkipOrDelayStateAndErrorForDevBox(
        id,
        statusesOnDevBoxes,
        statusesOnDevBoxActions,
        filterToRelevantDelayFailures
    );

const getDevBoxActionStateForDevBox = (
    devBox: DevBox,
    schedules: ScheduleMap,
    devBoxActions: SerializableMap<SerializableMap<DevBoxAction>>,
    displayTime: Date,
    statusesForDelayAllDevBoxActionsById: SerializableMap<Status>,
    statusesForDelayDevBoxActionById: SerializableMap<SerializableMap<Status>>,
    statusesForSkipAllDevBoxActionsById: SerializableMap<Status>,
    statusesForSkipDevBoxActionById: SerializableMap<SerializableMap<Status>>
): DevBoxActionState => {
    const { poolName, uri } = devBox;
    const { devCenter, projectName } = getTokensFromDevBoxDataPlaneUri(uri);

    const scheduleId = createScheduleDataPlaneUri({
        devCenter,
        poolName,
        projectName,
        scheduleName: DefaultScheduleName,
    });

    const schedule = get(schedules, scheduleId);

    const devBoxActionsForDevBox = getDevBoxActionsWithin24HoursForDevBox(uri, devBoxActions, schedule, displayTime);
    const nextScheduledStopTime = getSoonestScheduledStopTime(devBoxActionsForDevBox);
    const originalScheduledStopTime = getSoonestOriginalScheduledStopTime(devBoxActionsForDevBox);
    const scheduledDeleteTime = getScheduleDeleteDevBoxActionTimeForDevBox(uri, devBoxActions);

    const { state: delayState, errorCode: delayErrorCode } = getDelayStateAndErrorCodeForDevBox(
        uri,
        statusesForDelayAllDevBoxActionsById,
        statusesForDelayDevBoxActionById
    );

    const { state: skipState, errorCode: skipErrorCode } = getSkipStateAndErrorCodeForDevBox(
        uri,
        statusesForSkipAllDevBoxActionsById,
        statusesForSkipDevBoxActionById
    );

    return {
        nextScheduledStopTime,
        originalScheduledStopTime,
        delayState,
        delayErrorCode,
        skipState,
        skipErrorCode,
        scheduledDeleteTime,
    };
};

export const getDevBoxActionsWithin24HoursForDevBox = (
    devBoxId: string,
    devBoxActions: DevBoxToDevBoxActionMap,
    schedule: ScheduleContract | undefined,
    displayTime: Date
): DevBoxActionViewModel[] => {
    const devBoxActionsForDevBox = get(devBoxActions, devBoxId) ?? {};

    const devBoxActionsWithNextScheduledTime = values(devBoxActionsForDevBox).filter(
        isDevBoxActionWithNextScheduledTime
    );

    return devBoxActionsWithNextScheduledTime
        .map((devBoxAction) => {
            const originalScheduledTime: Date = isDevBoxScheduleAction(devBoxAction)
                ? getOriginalScheduledTimeForDevBox(schedule, devBoxAction.next?.scheduledTime) ?? displayTime
                : displayTime;

            return {
                ...devBoxAction,
                nextScheduledTime: devBoxAction.next?.scheduledTime,
                originalScheduledTime: isDevBoxStopOnDisconnectAction(devBoxAction) ? undefined : originalScheduledTime,
            };
        })
        .filter((devBoxAction) => {
            const { originalScheduledTime, nextScheduledTime } = devBoxAction;

            // if the next scheduled time is available, we should use it for the comparison
            const displayTimeToCompare = nextScheduledTime ? nextScheduledTime : originalScheduledTime;

            // Filter out actions scheduled for more than 24 hours from now and actions where both the nextScheduledTime and originalScheduledTime are undefined, as this is likely not a legitimate action
            return (
                displayTimeToCompare &&
                displayTime <= displayTimeToCompare &&
                getAreDatesLessThanNHoursApart(displayTimeToCompare, displayTime, 24)
            );
        });
};

export const getScheduleDeleteDevBoxActionTimeForDevBox = (
    devBoxId: string,
    devBoxActions: DevBoxToDevBoxActionMap
): Date | undefined => {
    const devBoxActionsForDevBox = get(devBoxActions, devBoxId) ?? {};

    const devBoxActionsWithNextScheduledTime = values(devBoxActionsForDevBox).filter(
        isDevBoxActionWithNextScheduledTime
    );

    const actions = devBoxActionsWithNextScheduledTime.filter((devBoxAction) =>
        isDevBoxScheduleDeleteAction(devBoxAction)
    );

    const selector = (action: DevBoxActionWithNextScheduledTime) => action?.next?.scheduledTime;

    const dateSortedActions = sortByInPlace(actions, selector, (a, b) => compareDates(a, b));

    return selector(dateSortedActions[0]);
};

const getPoolUriFromDevBox = (devBox: DevBox): string => {
    const { poolName, uri } = devBox;
    const { devCenter, projectName } = getTokensFromDevBoxDataPlaneUri(uri);

    return createPoolDataPlaneUri({ poolName, devCenter, projectName });
};

const getIsDevBoxCardContentReady = (
    devBox: DevBox,
    statusesForListDevBoxActions: SerializableMap<Status>,
    statusesForListSchedulesByProjectId: SerializableMap<Status>,
    statusesForGetRemoteConnection: SerializableMap<Status>,
    statusesForListDevBoxOperations: SerializableMap<Status>,
    statusForLoadDevBoxCardContent: Status
): boolean => {
    // If dev box card content discovery is complete, all dev box card content is ready
    if (isStatusTerminal(statusForLoadDevBoxCardContent)) {
        return true;
    }

    const { uri } = devBox;
    const { devCenter, projectName } = getTokensFromDevBoxDataPlaneUri(uri);
    const projectId = createProjectDataPlaneUri({ devCenter, projectName });
    const isScheduleReady = isStatusTerminal(get(statusesForListSchedulesByProjectId, projectId));

    // Using dev box key for upcoming action and remote connection states because these are devbox-level resources
    const isUpcomingActionReady = isStatusTerminal(get(statusesForListDevBoxActions, uri));
    const isRdpConnectionReady = isStatusTerminal(get(statusesForGetRemoteConnection, uri));
    const isOperationReady = isStatusTerminal(get(statusesForListDevBoxOperations, uri));

    return isScheduleReady && isUpcomingActionReady && isRdpConnectionReady && isOperationReady;
};

const getRepairOperation = (
    displayTime: Date,
    dismissedMessages: SerializableSet<string>,
    devBoxKey: string,
    actionState?: string,
    mostRecentOperation?: DevBoxOperation
): DevBoxRepairOperation | undefined => {
    if (actionState !== DevBoxState.Repaired || mostRecentOperation === undefined) {
        return undefined;
    }

    const isBannerDismissed = has(dismissedMessages, `RepairBanner;${devBoxKey};${mostRecentOperation?.operationId}`);

    if (isBannerDismissed || !mostRecentOperation?.endTime) {
        return undefined;
    }

    const hoursBetweenDates = getHoursBetweenDates(mostRecentOperation?.endTime, displayTime);

    // If it's been over 12 hours don't return an operation
    if (hoursBetweenDates !== undefined && hoursBetweenDates >= 12) {
        return undefined;
    }

    return mostRecentOperation as DevBoxRepairOperation;
};

const getSkipOrDelayErrorCode = (failures: Failure[]): DevBoxActionViewModelErrorCode => {
    if (failures.some((failure) => failure.code === CommonSkipDelayDevBoxActionErrorCode.DevBoxActionNotFound)) {
        return DevBoxActionViewModelErrorCode.DevBoxActionNotFound;
    }

    if (failures.some((failure) => failure.code === CommonSkipDelayDevBoxActionErrorCode.DevBoxActionAlreadyComplete)) {
        return DevBoxActionViewModelErrorCode.DevBoxActionAlreadyComplete;
    }

    if (
        failures.some(
            (failure) =>
                failure.code === CommonSkipDelayDevBoxActionErrorCode.DevBoxActionPreferenceUpdateViolatesThreshold
        )
    ) {
        return DevBoxActionViewModelErrorCode.DevBoxActionPreferenceUpdateViolatesThreshold;
    }

    if (failures.some((failure) => failure.code === CommonSkipDelayDevBoxActionErrorCode.DevBoxActionAlreadySkipped)) {
        return DevBoxActionViewModelErrorCode.DevBoxActionAlreadySkipped;
    }

    return DevBoxActionViewModelErrorCode.GenericError;
};

const getSkipOrDelayStateAndErrorForDevBox = (
    id: string,
    statusesOnDevBoxes: SerializableMap<Status>,
    statusesOnDevBoxActions: SerializableMap<SerializableMap<Status>>,
    filterToRelevantFailures: (statusesOnDevBoxActions: SerializableMap<Status>) => Failure[]
): { state: AsyncState; errorCode?: DevBoxActionViewModelErrorCode } => {
    const { state } = get(statusesOnDevBoxes, id) ?? Status();

    switch (state) {
        case AsyncState.Success: {
            const statusesForActions = get(statusesOnDevBoxActions, id) ?? SerializableMap<Status>();

            // Did all action operations also succeed? If so, this is a success
            if (every(statusesForActions, isStatusSuccessful)) {
                return { state: AsyncState.Success };
            }

            // Some request failed. Filter errors to relevant errors.
            const relevantFailures = filterToRelevantFailures(statusesForActions);

            // No relevant errors? This is a success.
            if (relevantFailures.length < 1) {
                return { state: AsyncState.Success };
            }

            // Get highest priority error code off of errors
            const errorCode = getSkipOrDelayErrorCode(relevantFailures);

            return { state: AsyncState.PartialSuccess, errorCode };
        }
        case AsyncState.Failed: {
            // All action operations failed
            const statusesForActions = get(statusesOnDevBoxActions, id) ?? SerializableMap<Status>();
            const failures = getFailuresFromStatuses(values(statusesForActions));
            const errorCode = getSkipOrDelayErrorCode(failures);

            return { state: AsyncState.Failed, errorCode };
        }
        case AsyncState.Error:
            // Internal error, don't need to look at action level states
            return { state: AsyncState.Error, errorCode: DevBoxActionViewModelErrorCode.GenericError };
        case AsyncState.InProgress:
        case AsyncState.Success:
        case AsyncState.NotStarted:
        default:
            return { state };
    }
};

const getSkipStateAndErrorCodeForDevBox = (
    id: string,
    statusesOnDevBoxes: SerializableMap<Status>,
    statusesOnDevBoxActions: SerializableMap<SerializableMap<Status>>
): { state: AsyncState; errorCode?: DevBoxActionViewModelErrorCode } =>
    getSkipOrDelayStateAndErrorForDevBox(id, statusesOnDevBoxes, statusesOnDevBoxActions, filterToRelevantSkipFailures);

const getSoonestDevBoxStopActionTime = (
    devBoxActions: DevBoxActionViewModel[],
    selector: (item: DevBoxActionViewModel) => Date | undefined
): Date | undefined => {
    const stopActions = devBoxActions.filter((devBoxAction) => devBoxAction.actionType === DevBoxActionActionType.Stop);

    if (stopActions.length === 0) {
        return undefined;
    }

    const dateSortedActions = sortByInPlace(stopActions, selector, (a, b) => compareDates(a, b));

    return selector(dateSortedActions[0]);
};

const getSoonestOriginalScheduledStopTime = (items: DevBoxActionViewModel[]) =>
    getSoonestDevBoxStopActionTime(items, (action) => action.originalScheduledTime);

const getSoonestScheduledStopTime = (items: DevBoxActionViewModel[]) =>
    getSoonestDevBoxStopActionTime(items, (action) => action.nextScheduledTime);

/**
 * Application state selectors
 */

// Choosing all customization groups per dev box
const getAllCustomizationGroupsByDevBox: StoreStateSelector<SerializableMap<CustomizationGroup[]>> = createSelector(
    [getCustomizationGroupsByDevBox],
    (customizationGroupsByDevBox) => {
        return map(customizationGroupsByDevBox, (groups) => values(groups));
    }
);

// Choosing one customization group per dev box
const getSingleCustomizationGroupsByDevBox: StoreStateSelector<SerializableMap<CustomizationGroup>> = createSelector(
    [getCustomizationGroupsByDevBox],
    (customizationGroupsByDevBox) => {
        // Since each map only has one customization group in it, we just get the first value in the values array
        return map(customizationGroupsByDevBox, (groups) => values(groups)[0]);
    }
);

const getSingleCustomizationGroupFailuresByDevBox: StoreStateSelector<SerializableMap<CustomizationFailure>> =
    createSelector([getCustomizationGroupFailuresByDevBox], (customizationGroupFailuresByDevBox) => {
        // Since each map only has one customization group in it, we just get the first value in the values array
        return map(customizationGroupFailuresByDevBox, (failures) => values(failures)[0]);
    });

const getAllSnapshotsByDevBox: StoreStateSelector<SerializableMap<DevBoxSnapshot[]>> = createSelector(
    [getSnapshotsByDevBox],
    (snapshotsByDevBox) => {
        return map(snapshotsByDevBox, (snapshots) => values(snapshots));
    }
);

const getMostRecentRepairOperationsByDevBox: StoreStateSelector<SerializableMap<DevBoxRepairOperation>> =
    createSelector([getDevBoxOperationsByDevBox], (devBoxOperationsByDevBox) => {
        let mostRecentRepairOperations: SerializableMap<DevBoxRepairOperation> = SerializableMap();

        forEach(devBoxOperationsByDevBox, (operations, id) => {
            const repairOperations = values(operations).filter(isDevBoxRepairOperation);
            repairOperations.sort((a, b) => compareDates(a.endTime, b.endTime));

            const mostRecentRepairOperation = last(repairOperations);

            // Most recent repair operation will be defined if there are any items in the list after filtering
            if (mostRecentRepairOperation) {
                mostRecentRepairOperations = set(mostRecentRepairOperations, id, mostRecentRepairOperation);
            }
        });

        return mostRecentRepairOperations;
    });

export const getDevBoxViewModels: StoreStateSelector<DevBoxViewModel[]> = createSelector(
    [
        getDevBoxes,
        getFailuresOnDevBoxesById,
        getPendingStatesForDevBoxesById,

        /**
         * Customizations
         */
        getSingleCustomizationGroupFailuresByDevBox,
        getSingleCustomizationGroupsByDevBox,
        getAllCustomizationGroupsByDevBox,

        /**
         * Dev box actions
         */
        getDevBoxActionsGroupedByDevBoxId,
        getStatusesForListDevBoxActionsByDevBoxId,
        getStatusesForDelayAllDevBoxActionsByDevBoxId,
        getStatusesForDelayDevBoxActionGroupedByDevBoxId,
        getStatusesForSkipAllDevBoxActionsByDevBoxId,
        getStatusesForSkipDevBoxActionGroupedByDevBoxId,

        /**
         * Dev box operations
         */
        getMostRecentRepairOperationsByDevBox,
        getStatusesForListDevBoxOperations,

        /**
         * Projects
         */
        getProjectsByDataPlaneId,
        getProjectsByDiscoveryServiceURI,

        /**
         * Remote connections
         */
        getRemoteConnectionsByDevBoxId,
        getStatusesForGetRemoteConnectionByDevBoxId,

        /**
         * Schedules
         */
        getSchedules,
        getStatusesForListSchedulesByProject,

        /**
         * Pool
         */

        getPools,

        /**
         * Snapshots
         */

        getAllSnapshotsByDevBox,

        /**
         * Miscellaneous
         */
        getDismissedMessages,
        getDisplayTime,
        getStatusForLoadDevBoxCardContent,
    ],
    (
        devBoxes,
        failuresOnDevBoxesById,
        pendingStatesById,
        customizationGroupFailures,
        customizationGroupMap,
        customizationGroupsMap,
        devBoxActionsByDevBoxId,
        statusesForListDevBoxActionsById,
        statusesForDelayAllDevBoxActionsById,
        statusesForDelayDevBoxActionById,
        statusesForSkipAllDevBoxActionsById,
        statusesForSkipDevBoxActionById,
        mostRecentRepairOperationsById,
        statusesForListDevBoxOperationsById,
        projectsByProjectId,
        projectsFromDiscoveryService,
        remoteConnectionsById,
        statusesForGetRemoteConnectionById,
        schedulesByScheduleId,
        statusesForListSchedulesByProjectId,
        pools,
        snapshotsByDevBox,
        dismissedMessages,
        displayTime,
        statusForLoadDevBoxCardContent
    ) => {
        const viewModels = devBoxes.map((devBox) => {
            const { actionState, uri } = devBox;

            const failureOnDevBox = get(failuresOnDevBoxesById, uri);
            const remoteConnection = get(remoteConnectionsById, uri);
            const pendingState = get(pendingStatesById, uri);
            const customizationGroup = get(customizationGroupMap, uri);
            const customizationGroups = get(customizationGroupsMap, uri);

            const customizationFailure = get(customizationGroupFailures, uri);
            const state = !!pendingState ? pendingState : getDevBoxState(devBox);
            const { webUrl, rdpConnectionUrl: remoteDesktopUrl, cloudPcConnectionUrl } = remoteConnection ?? {};
            const mostRecentRepairOperation = get(mostRecentRepairOperationsById, uri);

            const repairOperation = !!pendingState
                ? undefined
                : getRepairOperation(displayTime, dismissedMessages, uri, actionState, mostRecentRepairOperation);

            const { devCenter, projectName } = getTokensFromDevBoxDataPlaneUri(uri);

            const projectId = createProjectDataPlaneUri({ devCenter, projectName });

            const projectDisplayName = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
                ? get(projectsFromDiscoveryService, projectId)?.displayName
                : get(projectsByProjectId, projectId)?.properties?.displayName;

            const isCardContentReady = getIsDevBoxCardContentReady(
                devBox,
                statusesForListDevBoxActionsById,
                statusesForListSchedulesByProjectId,
                statusesForGetRemoteConnectionById,
                statusesForListDevBoxOperationsById,
                statusForLoadDevBoxCardContent
            );

            const devBoxActionState = getDevBoxActionStateForDevBox(
                devBox,
                schedulesByScheduleId,
                devBoxActionsByDevBoxId,
                displayTime,
                statusesForDelayAllDevBoxActionsById,
                statusesForDelayDevBoxActionById,
                statusesForSkipAllDevBoxActionsById,
                statusesForSkipDevBoxActionById
            );

            const poolUri = getPoolUriFromDevBox(devBox);

            const pool = get(pools, poolUri);

            const snapshots = get(snapshotsByDevBox, uri);

            return {
                resource: devBox,
                key: uri,
                failureOnDevBox,
                state,
                remoteDesktopUrl,
                webUrl,
                isCardContentReady,
                devBoxActionState,
                customizationGroup,
                customizationGroups,
                customizationFailure,
                repairOperation,
                pool,
                projectDisplayName,
                cloudPcConnectionUrl,
                snapshots,
            };
        });

        return sortByInPlace(
            viewModels,
            (item) => item.resource.name ?? '',
            (a, b) => compareStrings(a, b, true)
        );
    }
);

/**
 * Other selectors
 */

export const getDevBoxViewModelsInProject = (
    devBoxes: DevBoxViewModel[],
    selectedProjectId: string
): DevBoxViewModel[] =>
    devBoxes.filter((devBox) => {
        const { devCenter, projectName } = getTokensFromDevBoxDataPlaneUri(devBox.resource.uri);
        const projectId = createProjectDataPlaneUri({ devCenter, projectName });

        return areStringsEquivalent(selectedProjectId, projectId, true);
    });

export const getOriginalScheduledTimeForDevBox = (
    schedule: ScheduleContract | undefined,
    devBoxActionScheduledTime: Date | undefined
): Date | undefined => {
    const originalScheduledTime = !!schedule
        ? getDateFromTimeString(schedule.time, false, schedule.timeZone)
        : undefined;

    if (devBoxActionScheduledTime === undefined || originalScheduledTime === undefined) {
        return originalScheduledTime;
    }

    // Original scheduled time is only a time, not a date.
    // Let's guess that it is the same date as the next scheduled time.
    originalScheduledTime.setDate(devBoxActionScheduledTime.getDate());
    originalScheduledTime.setMonth(devBoxActionScheduledTime.getMonth());
    originalScheduledTime.setFullYear(devBoxActionScheduledTime.getFullYear());

    // If the original scheduled time is before or at the same time as the next scheduled time, our guess was right: the two times are on the same date.
    if (originalScheduledTime <= devBoxActionScheduledTime) {
        return originalScheduledTime;
    }

    // The original scheduled time we guessed is after the next scheduled time: it had to have actually been the day before.
    return addHours(originalScheduledTime, -24);
};

export const getShowConfigureRemoteDesktopQuickAction = (
    dismissedQuickActions: SerializableSet<QuickActionName>,
    operatingSystemFamily: OperatingSystemFamily
): boolean => {
    // Don't show this quick action on Windows if the "Open in RDP Client" feature is enabled
    if (operatingSystemFamily === OperatingSystemFamily.Windows) {
        return false;
    }

    // Otherwise, check if the action was disabled
    return !dismissedQuickActions.includes(QuickActionName.ConfigureRemoteDesktop);
};

export const getShowQuickActionsSection = (
    dismissedQuickActions: SerializableSet<QuickActionName>,
    operatingSystemFamily: OperatingSystemFamily
): boolean => getShowConfigureRemoteDesktopQuickAction(dismissedQuickActions, operatingSystemFamily);

export const getIsConnectViaAppTeachBubbleDismissed: StoreStateSelector<boolean> = createSelector(
    [getDismissedMessages],
    (dismissedMessages) => has(dismissedMessages, DismissableContentName.ConnectViaAppTeachableBubble)
);

export const getIsConnectViaAppTeachingPopoverForWelcomeTourDismissed: StoreStateSelector<boolean> = createSelector(
    [getDismissedMessages],
    (dismissedMessages) => has(dismissedMessages, DismissableContentName.ConnectViaAppTeachingPopoverForWelcomeTour)
);

export const getIsDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismissed: StoreStateSelector<boolean> =
    createSelector([getDismissedMessages], (dismissedMessages) =>
        has(dismissedMessages, DismissableContentName.DevboxSecondaryActionsTeachingPopoverForWelcomeTour)
    );

export const getIsConnectViaAppTeachingPopoverForWelcomeTourSkipped: StoreStateSelector<boolean> = createSelector(
    [getDismissedMessages],
    (dismissedMessages) =>
        has(dismissedMessages, DismissableContentName.ConnectViaAppTeachingPopoverForWelcomeTourSkipped)
);
