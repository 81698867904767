import { defineMessages } from 'react-intl';

export const sharedMessages = defineMessages({
    androidRemoteDesktopTitle: {
        id: 'DownloadRemoteDesktopCard_AndroidRemoteDesktopTitle_Text',
        defaultMessage: 'Microsoft Remote Desktop for Android',
        description: 'Name of the Remote Desktop software for Android platforms.',
    },
    iOSRemoteDesktopTitle: {
        id: 'DownloadRemoteDesktopCard_IOSRemoteDesktopTitle_Text',
        defaultMessage: 'Microsoft Remote Desktop for iOS',
        description: 'Name of the Remote Desktop software for iOS platforms.',
    },
    macRemoteDesktopTitle: {
        id: 'DownloadRemoteDesktopCard_MacRemoteDesktopTitle_Text',
        defaultMessage: 'Microsoft Remote Desktop for Mac',
        description: 'Name of the Remote Desktop software for Mac platforms.',
    },
    nonWindowsPrimaryButton: {
        id: 'DownloadRemoteDesktopButton_NonWindowsPrimaryButton_Text',
        defaultMessage: 'Go to app store',
        description: 'Button text for primary part of split button when on a non-Windows platform',
    },
    nonWindowsPrimaryButtonAriaLabel: {
        id: 'DownloadRemoteDesktopButton_NonWindowsPrimaryButton_AriaLabel',
        defaultMessage: 'Go to app store',
        description: 'Aria label for primary part of split button when on a non-Windows platform',
    },
    windowsPrimaryButtonAriaLabel: {
        id: 'DownloadRemoteDesktopButton_WindowsPrimaryButton_AriaLabel',
        defaultMessage: 'Download 64 bit',
        description: 'Aria label for primary part of split button when on a Windows platform',
    },
    windowsRemoteDesktopTitle: {
        id: 'DownloadRemoteDesktopCard_WindowsRemoteDesktopTitle_Text',
        defaultMessage: 'Microsoft Remote Desktop for Windows',
        description:
            'Name of the Remote Desktop software for Windows platforms. "Windows" here refers to the operating system.',
    },
});
