import { Divider, makeStyles, mergeClasses, tokens, typographyStyles } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDeploymentLogsPanelContext } from '../../../hooks/context/panels';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { FormPanel } from '../../common/form-panel';
import { EnvironmentOperationViewModel } from '../models';
import { DeploymentLogsDetails } from './deployment-logs-details';
import { getGroupedOperationsByDate } from './selectors';

export interface DeploymentLogsPanelProps {
    environmentOperations: EnvironmentOperationViewModel[];
    isOpen: boolean;
    name: string;
    onDismiss: () => void;
}

const messages = defineMessages({
    deploymentLogsPanelCloseButtonAriaLabel: {
        id: 'DeploymentLogsPanel_Close_AriaLabel',
        defaultMessage: 'Close deployment logs panel',
        description: 'Aria label for the deployment logs panel close button.',
    },
});

/**
 * Styles
 */

const useBodyStyles = makeStyles({
    root: {
        gap: '16px',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    },
    divider: {
        paddingTop: '5px',
        paddingBottom: '4px',
    },
});

const useItemStyles = makeStyles({
    subTitleText: typographyStyles.subtitle2,
    infoText: {
        ...typographyStyles.caption1,
        color: tokens.colorNeutralForeground2,
    },
});

/**
 * END Styles
 */

const DeploymentLogsPanelComponent: React.FC<DeploymentLogsPanelProps> = (props: DeploymentLogsPanelProps) => {
    const { environmentOperations, isOpen, name, onDismiss } = props;
    const operationsCount = environmentOperations.length;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const stackStyles = useStackWithFullWidthItemStyles();
    const bodyStyles = useBodyStyles();
    const itemStyles = useItemStyles();

    // Memoized data

    const numOperationsCompleted = React.useMemo(() => ({ operationsCount }), [operationsCount]);

    const infoText = React.useMemo(() => {
        return (
            <FormattedMessage
                id="DeploymentLogsPanel_OperationsCompleted_Count"
                defaultMessage="{operationsCount} operations completed"
                description="Number of operations in deployment logs"
                values={numOperationsCompleted}
            />
        );
    }, [numOperationsCompleted]);

    // Format date into MM/DD/YY and group operations by date
    const groupedOperations = React.useMemo(
        () => getGroupedOperationsByDate(environmentOperations),
        [environmentOperations]
    );

    return (
        <FormPanel
            isOpen={isOpen}
            onDismiss={onDismiss}
            closeButtonAriaLabel={formatMessage(messages.deploymentLogsPanelCloseButtonAriaLabel)}
            title={
                <FormattedMessage
                    id="DeploymentLogsPanel_Header_Text"
                    defaultMessage="Deployment Logs"
                    description="Header text for the deployment logs panel"
                />
            }
            isMediumSize
        >
            <div className={mergeClasses(stackStyles.root, bodyStyles.root)}>
                {/* Subheading (Environment name and number of operations) */}
                <div className={mergeClasses(stackStyles.item, bodyStyles.item)}>
                    <div className={itemStyles.subTitleText}>{name}</div>
                    <div className={itemStyles.infoText}>{infoText}</div>
                </div>

                <Divider className={bodyStyles.divider} />

                {/* Displays operations grouped by date */}
                {groupedOperations.map(([date, operations]) => (
                    <DeploymentLogsDetails key={date} date={date} operations={operations} />
                ))}
            </div>
        </FormPanel>
    );
};

export const DeploymentLogsPanelContextWrapper: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useDeploymentLogsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <DeploymentLogsPanelComponent {...properties} isOpen={isOpen} onDismiss={closePanel} />;
};

export default DeploymentLogsPanelContextWrapper;
