import {
    Button,
    ButtonProps,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Divider,
    FluentProvider,
    Input,
    makeStyles,
    mergeClasses,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    tokens,
    typographyStyles,
} from '@fluentui/react-components';
import { CheckmarkCircle16Regular, Copy16Regular, Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SubscriptionLink } from '../../../constants/avd';
import { OperatingSystemFamily } from '../../../constants/browser';
import { WindowsAppLink } from '../../../constants/windows-store-links';
import { useConfigureConnectionDialogContext } from '../../../hooks/context/dialogs';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { getRemoteAppOptionsSelector } from '../../../redux/selector/user-settings-selector';
import { useHorizontalStackStyles, useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { RemoteAppOption } from '../../../types/user-settings';
import { openInNewWindow, operatingSystemFamily } from '../../../utilities/browser';
import { DownloadRemoteDesktopButton } from './download-remote-desktop-button';

interface ConfigureConnectionDialogProps {
    isUsingRemoteDesktop: boolean;
    hidden?: boolean;
    onDismiss: () => void;
}

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'ConfigureRemoteDesktopDialog_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for "close" button in the "Configure Remote Desktop" dialog',
    },
    copyButton: {
        id: 'ConfigureRemoteDesktopDialog_CopyButton_Text',
        defaultMessage: 'Copy',
        description: 'Text for the "Copy" button in the "Configure Remote Desktop" dialog',
    },
    copyButtonAriaLabel: {
        id: 'ConfigureRemoteDesktopDialog_CopyButton_AriaLabel',
        defaultMessage: 'Copy',
        description: 'Aria label for the "Copy" button in the "Configure Remote Desktop" dialog',
    },
    doneButton: {
        id: 'ConfigureRemoteDesktopDialog_DoneButton_Text',
        defaultMessage: 'Done',
        description: 'Text for the "Done" button in the "Configure Remote Desktop" dialog',
    },
    doneButtonAriaLabel: {
        id: 'ConfigureRemoteDesktopDialog_DoneButton_AriaLabel',
        defaultMessage: 'Done',
        description: 'Aria label for the "Done" button in the "Configure Remote Desktop" dialog',
    },
    subscriptionUrlAriaLabel: {
        id: 'SubscriptionUrl_TextField_AriaLabel',
        defaultMessage: 'Subscription URL',
        description: 'Aria label for the Subscription URL textfield in the "Configure Remote Desktop" dialog',
    },
});

/**
 * Styles
 */

const useStepsContainerStyles = makeStyles({
    root: {
        gap: '34px',
    },
    item: {
        width: '100%',
    },
});

const useStepStyles = makeStyles({
    root: {
        gap: '12px',
        alignItems: 'start',
    },
    item: {
        width: '100%',
    },
});

const useInstructionStyles = makeStyles({
    root: {
        gap: '20px',
    },
    item: {
        width: '100%',
    },
});

const useSurfaceStyles = makeStyles({
    root: {
        gridTemplateColumns: 'none',
    },
});

const useInstructionHeaderStyles = makeStyles({
    root: typographyStyles.body1Strong,
});

const useURLBoxStyles = makeStyles({
    root: {
        width: '100%',
    },
});

const useCopyMessageStyles = makeStyles({
    root: {
        gap: '8px',
    },
    icon: {
        color: tokens.colorStatusSuccessForeground1,
    },
});

/**
 * END Styles
 */

const CopyButton: React.FC<ButtonProps> = (props) => {
    const styles = useCopyMessageStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    return (
        <Popover positioning="above-end">
            <PopoverTrigger disableButtonEnhancement>
                <Button {...props} appearance="transparent" icon={<Copy16Regular />} size="small" />
            </PopoverTrigger>

            <PopoverSurface>
                <div className={mergeClasses(horizontalStackStyles.root, styles.root)}>
                    <div className={mergeClasses(horizontalStackStyles.item, styles.icon)}>
                        <CheckmarkCircle16Regular />
                    </div>
                    <FormattedMessage
                        id="ConfigureRemoteDesktopDialog_CopyConfirmation_Text"
                        defaultMessage="Copied to clipboard"
                        description="Message confirming that the subscription URL was copied to a user's clipboard"
                    />
                </div>
            </PopoverSurface>
        </Popover>
    );
};

const onDownloadWindowsAppClicked = (): void => openInNewWindow(WindowsAppLink);

const ConfigureConnectionDialog: React.FC<ConfigureConnectionDialogProps> = (props: ConfigureConnectionDialogProps) => {
    const { hidden, onDismiss, isUsingRemoteDesktop } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const theme = useCurrentFluent2Theme();

    // Style hooks
    const stackStyles = useStackWithFullWidthItemStyles();
    const stepsContainerStyles = useStepsContainerStyles();
    const stepStyles = useStepStyles();
    const instructionStyles = useInstructionStyles();
    const surfaceStyles = useSurfaceStyles();
    const headerInstructionStyles = useInstructionHeaderStyles();
    const urlBoxStyles = useURLBoxStyles();

    // Callback hooks
    const onCopyClickedHandler = React.useCallback(async () => {
        await navigator.clipboard.writeText(SubscriptionLink);
    }, []);

    const onDismissHandler = React.useCallback(() => {
        onDismiss();
    }, [onDismiss]);

    return (
        <FluentProvider theme={theme}>
            <Dialog modalType="alert" open={!hidden} onOpenChange={onDismissHandler}>
                <DialogSurface>
                    <DialogBody className={surfaceStyles.root}>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={formatMessage(messages.closeButtonAriaLabel)}
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            }
                        >
                            {isUsingRemoteDesktop ? (
                                <FormattedMessage
                                    id="ConfigureConnectionDialog_RemoteDesktopHeader_Text"
                                    defaultMessage="Configure Remote Desktop"
                                    description="Title of the dialog when connecting using remote desktop"
                                />
                            ) : (
                                <FormattedMessage
                                    id="ConfigureConnectionDialog_WindowsAppHeader_Text"
                                    defaultMessage="Configure Windows App"
                                    description="Title of the dialog when connecting with Windows app"
                                />
                            )}
                        </DialogTitle>
                        <DialogContent>
                            <div className={mergeClasses(stackStyles.root, stepsContainerStyles.root)}>
                                <div className={mergeClasses(stackStyles.item, stepsContainerStyles.item)}>
                                    <div className={mergeClasses(stackStyles.root, instructionStyles.root)}>
                                        <div className={mergeClasses(stackStyles.item, headerInstructionStyles.root)}>
                                            {isUsingRemoteDesktop ? (
                                                <FormattedMessage
                                                    id="ConfigureConnectionDialog_DownloadRemoteDesktop_Text"
                                                    defaultMessage="1. Download and install the Remote Desktop application."
                                                    description="Text for instructions on downloading a Remote Desktop client."
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    id="ConfigureConnectionDialog_DownloadWindowsApp_Text"
                                                    defaultMessage="1. Download and install the Windows App."
                                                    description="Text for instructions on downloading Windows app."
                                                />
                                            )}
                                        </div>

                                        <div className={mergeClasses(stackStyles.item, instructionStyles.item)}>
                                            {isUsingRemoteDesktop ? (
                                                <Button onClick={onDownloadWindowsAppClicked}>
                                                    <DownloadRemoteDesktopButton />
                                                </Button>
                                            ) : (
                                                <Button onClick={onDownloadWindowsAppClicked}>
                                                    <FormattedMessage
                                                        id="ConfigureConnectionDialog_DownloadWindowsApp_Button"
                                                        defaultMessage="Windows App for Mac"
                                                        description="Label for Windows App download button"
                                                    />
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div className={stackStyles.item}>
                                    <div className={mergeClasses(stackStyles.root, instructionStyles.root)}>
                                        <div className={mergeClasses(stackStyles.item, headerInstructionStyles.root)}>
                                            {isUsingRemoteDesktop ? (
                                                <FormattedMessage
                                                    id="ConfigureConnectionDialog_ConnectToRemoteDesktop_Title"
                                                    defaultMessage="2. Configure the Remote Desktop feed"
                                                    description="Heading for the instructions on connecting to your dev box through the remote desktop app"
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    id="ConfigureConnectionDialog_ConnectToWindowsApp_Title"
                                                    defaultMessage="2. Configure the Windows App feed"
                                                    description="Heading for the instructions on connecting to your dev box through windows app."
                                                />
                                            )}
                                        </div>
                                        <div className={stackStyles.item}>
                                            {operatingSystemFamily === OperatingSystemFamily.Windows ? (
                                                <FormattedMessage
                                                    id="ConfigureConnectionDialog_ConnectToRemoteDesktopFromWindows_Text"
                                                    defaultMessage="Once Remote Desktop is installed, connect your dev box with a subscription URL. Copy the URL, select 'Subscribe with URL' in the Remote Desktop app, then paste it."
                                                    description="Text for first set of instructions on connecting to your dev box using a Remote Desktop client on Windows."
                                                />
                                            ) : isUsingRemoteDesktop ? (
                                                <FormattedMessage
                                                    id="ConfigureConnectionDialog_ConnectToRemoteDesktopFromNonWindows_Text"
                                                    defaultMessage="Once Remote Desktop is installed, you’ll need to connect your dev box with a subscription URL. Copy the URL, select 'Add Workspace' in the Remote Desktop app, then paste it."
                                                    description="Text for first set of instructions on connecting to your dev box using a Remote Desktop client on non-Windows platforms."
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    id="ConfigureConnectionDialog_ConnectToWindowsAppFromNonWindows_Text"
                                                    defaultMessage="Once Windows App is installed, you’ll need to connect your dev box with a subscription URL. Copy the URL, select 'Add Workspace' in the Windows App, then paste it."
                                                    description="Text for first set of instructions on connecting to your dev box using a Remote Desktop client on non-Windows platforms."
                                                />
                                            )}
                                        </div>

                                        <div className={mergeClasses(stackStyles.item, stepStyles.item)}>
                                            <Input
                                                className={urlBoxStyles.root}
                                                readOnly
                                                defaultValue={SubscriptionLink}
                                                aria-label={formatMessage(messages.subscriptionUrlAriaLabel)}
                                                contentAfter={
                                                    <CopyButton
                                                        aria-label={formatMessage(messages.copyButtonAriaLabel)}
                                                        onClick={onCopyClickedHandler}
                                                    />
                                                }
                                            />
                                        </div>
                                        <div className={stackStyles.item}>
                                            {isUsingRemoteDesktop ? (
                                                <FormattedMessage
                                                    id="ConfigureConnectionDialog_ConnectToRemoteDesktop_Text"
                                                    defaultMessage="All your dev boxes will be available within your Remote Desktop client app once this process is complete."
                                                    description="Text for second set of instructions on connecting to your dev box using a Remote Desktop client."
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    id="ConfigureConnectionDialog_ConnectToWindowsApp_Text"
                                                    defaultMessage="All your dev boxes will be available within the Windows App once this process is complete."
                                                    description="Text for second set of instructions on connecting to your dev box using Windows App."
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </FluentProvider>
    );
};

export const ConfigureConnectionDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface } = useConfigureConnectionDialogContext();
    const userRemoteAppOption = useSelector(getRemoteAppOptionsSelector);

    if (!isOpen) {
        return <></>;
    }

    const isUsingRemoteDesktop = userRemoteAppOption === RemoteAppOption.useAvd;

    return (
        <ConfigureConnectionDialog
            onDismiss={closeSurface}
            hidden={!isOpen}
            isUsingRemoteDesktop={isUsingRemoteDesktop}
        />
    );
};

export default ConfigureConnectionDialogContextWrapper;
