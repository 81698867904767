import { createSelector } from '@reduxjs/toolkit';
import { DevBoxAction } from '../../../../models/dev-box-action';
import { SerializableMap } from '../../../../types/serializable-map';
import { isDevBoxActionWithin24HoursOfTime } from '../../../../utilities/dev-box-action';
import { filter, map, values } from '../../../../utilities/serializable-map';
import {
    IndexedStoreStateSelector,
    StoreStateSelector,
    forwardIdToSelector,
    getDataById,
} from '../../../selector/common';
import { getDevBoxActionsGroupedByDevBoxId } from '../../../selector/dev-box-action-selectors';
import { getDisplayTime } from '../../../selector/time-selectors';

/**
 * Application state selectors
 */

export const getDevBoxActionsWithin24HoursGroupedByDevBoxId: StoreStateSelector<
    SerializableMap<SerializableMap<DevBoxAction>>
> = createSelector([getDevBoxActionsGroupedByDevBoxId, getDisplayTime], (devBoxActionsByDevBox, displayTime) =>
    map(devBoxActionsByDevBox, (devBoxActions) =>
        filter(devBoxActions, (action) => isDevBoxActionWithin24HoursOfTime(action, displayTime))
    )
);

export const getIdsOfDevBoxActionsWithin24HoursForDevBox: IndexedStoreStateSelector<string[]> = createSelector(
    [getDevBoxActionsWithin24HoursGroupedByDevBoxId, forwardIdToSelector],
    (devBoxActionsWithin24HoursByDevBox, devBoxId) => {
        const devBoxActionsWithin24Hours = getDataById(devBoxActionsWithin24HoursByDevBox, devBoxId);

        if (!devBoxActionsWithin24Hours) {
            return [];
        }

        return values(devBoxActionsWithin24Hours).map((devBoxAction) => devBoxAction.uri);
    }
);

export const getIdsOfDevBoxActionsForDevBox: IndexedStoreStateSelector<string[]> = createSelector(
    [getDevBoxActionsGroupedByDevBoxId, forwardIdToSelector],
    (devBoxActionsByDevBox, devBoxId) => {
        const devBoxActions = getDataById(devBoxActionsByDevBox, devBoxId);

        if (!devBoxActions) {
            return [];
        }

        return values(devBoxActions).map((devBoxAction) => devBoxAction.uri);
    }
);
