import { Button } from '@fluentui/react-components';
import { StoreMicrosoft20Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Metric, Property } from '../../../constants/telemetry';
import { RemoteDesktopLink, WindowsAppLink } from '../../../constants/windows-store-links';
import { RemoteAppOption } from '../../../types/user-settings';
import { openInNewWindow } from '../../../utilities/browser';
import { trackMetric } from '../../../utilities/telemetry/channel';

type ConnectViaAppDownloadButtonProps = {
    remoteAppOption?: RemoteAppOption;
};

const messages = defineMessages({
    downloadWindowsAppButtonAriaLabel: {
        id: 'DownloadWindowsAppButton_Button_AriaLabel',
        defaultMessage: 'Get the Windows app',
        description: 'Aria label for primary action of "download windows app" button',
    },
    downloadRemoteDesktopButtonAriaLabel: {
        id: 'DownloadRemoteDesktop_Button_AriaLabel',
        defaultMessage: 'Get Remote Desktop',
        description: 'Aria label for "get remote desktop" button',
    },
});

const onWindowsAppOptionClicked = (): void => {
    trackMetric(Metric.InstallRemoteClientLinkClicked, 1, {
        properties: {
            [Property.Link]: WindowsAppLink,
        },
    });

    return openInNewWindow(WindowsAppLink);
};

const onRemoteDesktopOptionClicked = (): void => {
    trackMetric(Metric.InstallRemoteClientLinkClicked, 1, {
        properties: {
            [Property.Link]: RemoteDesktopLink,
        },
    });

    return openInNewWindow(RemoteDesktopLink);
};

export const ConnectViaAppDownloadButton: React.FC<ConnectViaAppDownloadButtonProps> = (
    props: ConnectViaAppDownloadButtonProps
) => {
    // Intl hooks
    const { formatMessage } = useIntl();
    const { remoteAppOption } = props;

    const useWindowsApp = React.useMemo(() => {
        return remoteAppOption === RemoteAppOption.useWindowsApp;
    }, [remoteAppOption]);

    return (
        <Button
            appearance="secondary"
            aria-label={
                useWindowsApp
                    ? formatMessage(messages.downloadWindowsAppButtonAriaLabel)
                    : formatMessage(messages.downloadRemoteDesktopButtonAriaLabel)
            }
            icon={<StoreMicrosoft20Regular />}
            onClick={useWindowsApp ? onWindowsAppOptionClicked : onRemoteDesktopOptionClicked}
        >
            {useWindowsApp ? (
                <FormattedMessage
                    id="DownloadWindowsAppButton_Button_Text"
                    defaultMessage="Get the Windows app"
                    description='Text for primary action of "Download Windows app" button'
                />
            ) : (
                <FormattedMessage
                    id="DownloadRemoteDesktop_Button_Text"
                    defaultMessage="Get Remote Desktop"
                    description='Text for "get remote desktop" button'
                />
            )}
        </Button>
    );
};

export default ConnectViaAppDownloadButton;
