import {
    Divider,
    makeStyles,
    mergeClasses,
    tokens,
    Tree,
    TreeItem,
    TreeItemLayout,
    typographyStyles,
} from '@fluentui/react-components';
import { Buffer } from 'buffer';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCustomizationDetailsPanelContext } from '../../hooks/context/panels';
import { getCustomizationGroupUriFromTaskLogDataPlaneUri } from '../../ids/customization-task-log';
import {
    CustomizationGroup,
    CustomizationGroupStatus,
    CustomizationTask,
    CustomizationTaskStatus,
} from '../../models/customization';
import { useStackWithFullWidthItemStyles } from '../../themes/styles/flexbox-styles';
import { getOverallCustomizationGroup } from '../../utilities/customization-group';
import { entries } from '../../utilities/serializable-map';
import { getMillisecondsBetween } from '../../utilities/time';
import { FormPanel } from '../common/form-panel';
import { wingetBase64ParameterName } from '../common/winget-configuration/models';
import { isWingetConfiguration } from '../common/winget-configuration/selectors';
import { WingetConfigurationDetails } from '../common/winget-configuration/winget-configuration-details';
import CustomizationTaskHeaderContainer from './customization-task-details';

export interface CustomizationDetailsPanelProps {
    devBoxName?: string;
    customizationGroup: CustomizationGroup;
    customizationGroups?: CustomizationGroup[];
    isOpen: boolean;
    onDismiss: () => void;
}

/**
 * Styles
 */

const usePanelStyles = makeStyles({
    titleName: typographyStyles.subtitle2,
    infoText: {
        ...typographyStyles.caption1,
        color: tokens.colorNeutralForeground2,
    },
});

const useGroupListStyles = makeStyles({
    taskDetails: {
        ...typographyStyles.body1,
        fontFamily: tokens.fontFamilyMonospace,
        wordBreak: 'break-all',
    },
});

const useBodyStyles = makeStyles({
    root: {
        gap: '20px',
    },
});

/**
 * END Styles
 */

const CustomizationDetailsPanelComponent: React.FC<CustomizationDetailsPanelProps> = (
    props: CustomizationDetailsPanelProps
) => {
    const { devBoxName, isOpen, onDismiss, customizationGroup, customizationGroups } = props;
    const { uri } = customizationGroup;

    const group = getOverallCustomizationGroup(customizationGroups ?? []);
    const { tasks, status, startTime, endTime } = group;

    // Intl hooks
    const { formatMessage, formatNumber } = useIntl();

    // Style hooks
    const panelStyles = usePanelStyles();
    const groupListStyles = useGroupListStyles();
    const stackStyles = useStackWithFullWidthItemStyles();
    const bodyStyles = useBodyStyles();

    const tasksCompleted = React.useMemo(
        () => (tasks ? tasks.filter((task) => task.status === CustomizationTaskStatus.Succeeded) : []),
        [tasks]
    );

    const tasksFailed = React.useMemo(
        () =>
            tasks
                ? tasks.filter(
                      (task) =>
                          task.status === CustomizationTaskStatus.Failed ||
                          task.status === CustomizationTaskStatus.FailedValidation
                  )
                : [],
        [tasks]
    );

    const completionTime = React.useMemo(
        () => (endTime && startTime ? getMillisecondsBetween(startTime, endTime) / 1000 : 0),
        [endTime, startTime]
    );

    const tasksCompleteValues = React.useMemo(
        () => ({
            tasksCompleted: formatNumber(tasksCompleted.length),
            tasksFailed: formatNumber(tasksFailed.length),
            completionTime: formatNumber(completionTime, { style: 'unit', unit: 'second', unitDisplay: 'narrow' }),
        }),
        [tasksFailed, tasksCompleted, completionTime]
    );

    const infoText = React.useMemo(() => {
        if (status === CustomizationGroupStatus.NotStarted) {
            return (
                <FormattedMessage
                    id="CustomizationDetailsPanel_PreparingToApplyCustomizations_Text"
                    defaultMessage="Preparing to apply the following customizations:"
                />
            );
        }

        if (status === CustomizationGroupStatus.Running) {
            return (
                <FormattedMessage
                    id="CustomizationDetailsPanel_ApplyingCustomizations_Text"
                    defaultMessage="We're applying the following customizations:"
                    description='Info message for applying customizations in "Customization details" panel'
                />
            );
        }

        if (tasksCompleted.length === 1) {
            return (
                <FormattedMessage
                    id="CustomizationDetailsPanel_SingleCustomizationTaskComplete_Text"
                    defaultMessage="1 operation completed | {tasksFailed} operations failed | Duration {completionTime}"
                    description="Message informing that one task completed and how many tasks failed in what amount of time"
                    values={tasksCompleteValues}
                />
            );
        }

        return (
            <FormattedMessage
                id="CustomizationDetailsPanel_MultipleCustomizationTasksComplete_Text"
                defaultMessage="{tasksCompleted} operations completed | {tasksFailed} operations failed | Duration {completionTime}"
                description="Message informing how many tasks completed and how many tasks failed in what amount of time"
                values={tasksCompleteValues}
            />
        );
    }, [formatMessage, status, tasksCompleteValues, tasksCompleted]);

    const onRenderParameters = React.useCallback(
        (parameters) => {
            const parametersEntries = entries(parameters);

            if (parametersEntries.length === 1 && parametersEntries[0][0] === wingetBase64ParameterName) {
                const base64String = String(parametersEntries[0][1]);
                const decodedString = Buffer.from(base64String, 'base64').toString('utf8');

                if (isWingetConfiguration(decodedString)) {
                    return <WingetConfigurationDetails content={decodedString} />;
                }
            }

            return parametersEntries.map((param, index) => (
                <TreeItem itemType="leaf" key={index}>
                    <TreeItemLayout>
                        <div className={groupListStyles.taskDetails}>
                            <FormattedMessage
                                id="CustomizationDetailsPanel_CustomizationTaskParameter_Text"
                                defaultMessage="{parameterName}: {parameterValue}"
                                description="Parameter name and value for a customization task with parameters. {parameterName} and {parameterValue} should not be localized."
                                values={{ parameterName: param[0], parameterValue: param[1] }}
                            />
                        </div>
                    </TreeItemLayout>
                </TreeItem>
            ));
        },
        [groupListStyles]
    );

    const getCustomizationGroupUriForTask = React.useCallback(
        (task: CustomizationTask) => {
            const taskLogUri = task?.logUri;

            // TODO: Update the store to fetch all the customizationGroups instead of just the "initial" after creating a devbox so that taskLogUri is available
            if (!taskLogUri) {
                // This will happen if the customizationGroup has just been created and the taskLogUri hasn't been created yet.
                // Fallback to uri
                return uri;
            }

            return getCustomizationGroupUriFromTaskLogDataPlaneUri(taskLogUri);
        },
        [uri, tasks]
    );

    const branchList: JSX.Element[] = React.useMemo(() => {
        return tasks
            ? tasks.map((task) => (
                  <TreeItem
                      itemType="branch"
                      /* eslint-disable @typescript-eslint/no-non-null-assertion */
                      // Justification: task.id should always be defined, this is just a spec artifact
                      key={task.id!}
                      /* eslint-disable @typescript-eslint/no-non-null-assertion */
                  >
                      <CustomizationTaskHeaderContainer
                          task={task}
                          customizationGroupUri={getCustomizationGroupUriForTask(task)}
                          name={task.displayName ?? task.name}
                      />
                      <Tree>{onRenderParameters(task.parameters)}</Tree>
                  </TreeItem>
              ))
            : [];
    }, [tasks, onRenderParameters]);

    return (
        <FormPanel
            isOpen={isOpen}
            onDismiss={onDismiss}
            title={
                <FormattedMessage
                    id="CustomizationDetailsPanel_Title_Text"
                    defaultMessage="Customization details"
                    description='Title of the "Customization details" panel'
                />
            }
            isMediumSize
        >
            <div className={mergeClasses(stackStyles.root, bodyStyles.root)}>
                <div className={stackStyles.item}>
                    <div className={panelStyles.titleName}>{devBoxName}</div>
                    <div className={panelStyles.infoText}>{infoText}</div>
                </div>
                <Divider />
                <div className={stackStyles.item}>{tasks && <Tree>{branchList}</Tree>}</div>
            </div>
        </FormPanel>
    );
};

const CustomizationDetailsPanelContainer: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useCustomizationDetailsPanelContext();

    return <CustomizationDetailsPanelComponent isOpen={isOpen} onDismiss={closePanel} {...properties} />;
};

export const CustomizationDetailsPanelContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen } = useCustomizationDetailsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <CustomizationDetailsPanelContainer />;
};

export default CustomizationDetailsPanelContextWrapper;
