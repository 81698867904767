import { FontSizes, FontWeights } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FeatureFlagName } from 'src/constants/features';
import { isFeatureFlagEnabled } from 'src/utilities/features';
import { CannotCreateResourcesElement } from '../../components/blocking-states/cannot-create-resources';
import GuestAccountsNotSupported from '../../components/blocking-states/guest-accounts-not-supported';
import { NoProjects } from '../../components/blocking-states/no-projects';
import { NoResourcesCreateCard } from '../../components/no-resources-create-card';
import { WelcomeTourDialog } from '../../components/welcome-tour-dialog/welcome-tour-dialog';
import emptyDevBoxImage from '../../content/images/Empty_devbox_dark.webp';
import emptyEnvironmentImage from '../../content/images/Empty_env_dark.webp';
import { useAddDevBoxPanelContext, useAddEnvironmentPanelContext } from '../../hooks/context/panels';
import CenteredPortalLayout from '../../layouts/centered-portal-layout';
import { getWelcomeTourSeenStatus } from '../../redux/selector/application-selectors';
import { getHasDevBoxes } from '../../redux/selector/dev-box-selectors';
import {
    ActionAbility,
    DevBoxCreateAbilityState,
    EnvironmentCreateAbilityState,
} from '../../redux/selector/display/create-ability/models';
import {
    getDevBoxCreateAbilityState,
    getEnvironmentCreateAbilityState,
} from '../../redux/selector/display/create-ability/selectors';
import { DisplayState, getDisplayState } from '../../redux/selector/display/display-state';
import { ResourceUserState } from '../../redux/selector/display/user-state/models';
import { getDevBoxUserState, getEnvironmentUserState } from '../../redux/selector/display/user-state/selectors';
import { getHasEnvironments } from '../../redux/selector/environment-selectors';
import { getOrganizationDisplayName, getUserDisplayName, getUserGivenName } from '../../redux/selector/graph-selectors';
import { useHorizontalStackStyles, useStackStyles } from '../../themes/styles/flexbox-styles';
import { Resources } from './resources';

interface HomeIndexPageBodyProps {
    hasDevBoxes: boolean;
    hasEnvironments: boolean;
    devBoxUserState: ResourceUserState;
    environmentUserState: ResourceUserState;
    devBoxCreateAbilityState: DevBoxCreateAbilityState;
    environmentCreateAbilityState: EnvironmentCreateAbilityState;
    displayState: DisplayState;
}

interface HomeIndexPageComponentProps {
    givenName: string | undefined;
    displayName: string;
    hasDevBoxes: boolean;
    hasEnvironments: boolean;
    organizationDisplayName: string;
    devBoxUserState: ResourceUserState;
    environmentUserState: ResourceUserState;
    devBoxCreateAbilityState: DevBoxCreateAbilityState;
    environmentCreateAbilityState: EnvironmentCreateAbilityState;
    displayState: DisplayState;
}

const messages = defineMessages({
    devBoxHeaderText: {
        id: 'NoResources_DevboxHeader_Text',
        defaultMessage: 'Create a dev box',
        description: 'Header text for no resources dev box card',
    },
    devBoxBodyText: {
        id: 'NoResources_DevboxBody_Text',
        defaultMessage: 'Dev boxes provide ready-to-code project-specific workstations in the cloud.',
        description: 'Body text for no resources dev box card',
    },
    devBoxButtonText: {
        id: 'NoResources_DevboxButton_Text',
        defaultMessage: 'New dev box',
        description: 'Button text for no resources dev box card',
    },
    environmentHeaderText: {
        id: 'NoResources_EnvironmentHeader_Text',
        defaultMessage: 'Create an environment',
        description: 'Header text for no resources environment card',
    },
    environmentBodyText: {
        id: 'NoResources_EnvironmentBody_Text',
        defaultMessage: 'Environments provide the resources needed to deploy and run your applications.',
        description: 'Body text for no resources environnment card',
    },
    environmentButtonText: {
        id: 'NoResources_EnvironmentButton_Text',
        defaultMessage: 'New environment',
        description: 'Button text for no environment dev box card',
    },
    writeBothDevBoxesAndEnvironments: {
        id: 'NoResources_WriteBothDevBoxesAndEnvironments',
        defaultMessage: 'Select one of the options below to get started with your first resource.',
        description: 'Text for when user can write both dev boxes and environments',
    },
    writeSingleResource: {
        id: 'NoResources_WriteSingleResource',
        defaultMessage: 'Create your first resource to get started.',
        description: 'Text for when user can write only one resource',
    },
});

/**
 * Styles
 */

const useIndexPageStyles = makeStyles({
    root: {
        marginTop: '56px',
    },
});

const useWelcomeMessageContainerStyles = makeStyles({
    h1: {
        fontSize: FontSizes.size28,
        fontWeight: FontWeights.semibold,
        lineHeight: '36px',
        textAlign: 'left',
    },
});

const usePortalLayoutStyles = makeStyles({
    root: {
        height: 'auto',
        flexGrow: '0',
    },
    item: {
        paddingBottom: '20px',
        paddingTop: '40px',
    },
});

const usePortalLayoutWithProgressBarOffsetStyles = makeStyles({
    root: {
        height: 'auto',
        flexGrow: '0',
    },
    item: {
        paddingBottom: '20px',
        paddingTop: '87px',
    },
});

const useHeaderStyles = makeStyles({
    root: {
        gap: '6px',
        alignItems: 'start',
    },
});

const useCenteredHeaderStyles = makeStyles({
    root: {
        gap: '6px',
        alignItems: 'center',
    },
});

const useHorizontalStackGridStyles = makeStyles({
    root: { flexWrap: 'wrap', gap: '50px' },
});

/**
 * End Styles
 */
interface EmptyResourcesStateComponentProps {
    isDevBoxUser: boolean;
    isEnvironmentUser: boolean;
    canWriteDevBoxes: boolean;
    canWriteEnvironments: boolean;
    onAddDevBoxButtonClicked: () => void;
    onAddEnvironmentButtonClicked: () => void;
}

const EmptyResourcesStateComponent: React.FC<EmptyResourcesStateComponentProps> = (
    props: EmptyResourcesStateComponentProps
) => {
    const {
        isDevBoxUser,
        isEnvironmentUser,
        canWriteDevBoxes,
        canWriteEnvironments,
        onAddDevBoxButtonClicked,
        onAddEnvironmentButtonClicked,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const horizontalStackStyles = useHorizontalStackStyles();
    const horizontalStackGridStyles = useHorizontalStackGridStyles();

    return (
        <div className={mergeClasses(horizontalStackStyles.root, horizontalStackGridStyles.root)}>
            <div className={horizontalStackStyles.item}>
                {isDevBoxUser && canWriteDevBoxes && (
                    <NoResourcesCreateCard
                        id="NoResourcesCreateCard_NewDevBox_Button"
                        previewImageSrc={emptyDevBoxImage}
                        headerText={formatMessage(messages.devBoxHeaderText)}
                        bodyText={formatMessage(messages.devBoxBodyText)}
                        buttonText={formatMessage(messages.devBoxButtonText)}
                        buttonAriaLabel={formatMessage(messages.devBoxButtonText)}
                        onButtonClick={onAddDevBoxButtonClicked}
                    />
                )}
            </div>
            <div className={horizontalStackStyles.item}>
                {isEnvironmentUser && canWriteEnvironments && (
                    <NoResourcesCreateCard
                        previewImageSrc={emptyEnvironmentImage}
                        headerText={formatMessage(messages.environmentHeaderText)}
                        bodyText={formatMessage(messages.environmentBodyText)}
                        buttonText={formatMessage(messages.environmentButtonText)}
                        buttonAriaLabel={formatMessage(messages.environmentButtonText)}
                        onButtonClick={onAddEnvironmentButtonClicked}
                    />
                )}
            </div>
        </div>
    );
};

const HomeIndexPageBody: React.FC<HomeIndexPageBodyProps> = (props: HomeIndexPageBodyProps) => {
    const {
        hasDevBoxes,
        hasEnvironments,
        devBoxUserState,
        environmentUserState,
        devBoxCreateAbilityState,
        environmentCreateAbilityState,
        displayState,
    } = props;

    // Context hooks
    const { openSurface: onAddDevBoxButtonClicked } = useAddDevBoxPanelContext();
    const { openSurface: onAddEnvironmentButtonClicked } = useAddEnvironmentPanelContext();

    const isDevBoxUser = devBoxUserState === ResourceUserState.IsUser;
    const isEnvironmentUser = environmentUserState === ResourceUserState.IsUser;
    const canWriteDevBoxes = devBoxCreateAbilityState.createAbility === ActionAbility.CanPerformAction;
    const canWriteEnvironments = environmentCreateAbilityState.createAbility === ActionAbility.CanPerformAction;

    const firstTargetElementSelector = React.useMemo(() => {
        if (isDevBoxUser && isEnvironmentUser) {
            return `[aria-label="New"]`;
        } else if (isDevBoxUser) {
            return `[aria-label="New dev box"]`;
        } else {
            return `[aria-label="New environment"]`;
        }
    }, [isDevBoxUser, isEnvironmentUser]);

    // Selector hooks
    const seenOrSkippedWelcomeTour = useSelector(getWelcomeTourSeenStatus);

    // States to hold the three popover anchor elements.
    const [firstTargetElement, setFirstTargetElement] = React.useState<Element | null>(null);
    const [secondTargetElement, setSecondTargetElement] = React.useState<Element | null>(null);
    const [thirdTargetElement, setThirdTargetElement] = React.useState<Element | null>(null);

    // This is done to ensure they are loaded into DOM before we anchor the popover onto them.
    React.useEffect(() => {
        const firstTargetElement = document.querySelector(firstTargetElementSelector);
        const secondTargetElement = document.querySelector(`[aria-label="User settings menu"]`);
        const thirdTargetElement = document.querySelector(`[aria-label="Help menu"]`);
        setFirstTargetElement(firstTargetElement);
        setSecondTargetElement(secondTargetElement);
        setThirdTargetElement(thirdTargetElement);
    }, []);

    switch (displayState) {
        case DisplayState.Loading:
            return <></>;
        case DisplayState.HasResources:
            return <Resources hasDevBoxes={hasDevBoxes} hasEnvironments={hasEnvironments} />;
        case DisplayState.EmptyState:
            return (
                <>
                    <WelcomeTourDialog
                        showDialog={!seenOrSkippedWelcomeTour}
                        createNewTeachingPopoverTargetElement={firstTargetElement}
                        settingsTeachingPopoverTargetElement={secondTargetElement}
                        helpMenuTeachingPopoverTargetElement={thirdTargetElement}
                    />
                    <EmptyResourcesStateComponent
                        canWriteDevBoxes={canWriteDevBoxes}
                        canWriteEnvironments={canWriteEnvironments}
                        isDevBoxUser={isDevBoxUser}
                        isEnvironmentUser={isEnvironmentUser}
                        onAddDevBoxButtonClicked={onAddDevBoxButtonClicked}
                        onAddEnvironmentButtonClicked={onAddEnvironmentButtonClicked}
                    />
                </>
            );
        // For the guest user FF, we'll want to show empty
        case DisplayState.GuestAccount:
            if (isFeatureFlagEnabled(FeatureFlagName.EnableGuestUser)) {
                return (
                    <EmptyResourcesStateComponent
                        canWriteDevBoxes={canWriteDevBoxes}
                        canWriteEnvironments={canWriteEnvironments}
                        isDevBoxUser={isDevBoxUser}
                        isEnvironmentUser={isEnvironmentUser}
                        onAddDevBoxButtonClicked={onAddDevBoxButtonClicked}
                        onAddEnvironmentButtonClicked={onAddEnvironmentButtonClicked}
                    />
                );
            }
        case DisplayState.FullScreenMisconfiguredState:
        default:
            return (
                <CannotCreateResourcesElement
                    isEnvironmentUser={isEnvironmentUser}
                    isDevBoxUser={isDevBoxUser}
                    cannotCreateEnvironmentReason={environmentCreateAbilityState.reason}
                    cannotCreateDevBoxReason={devBoxCreateAbilityState.reason}
                />
            );
    }
};

export const HomeIndexPageComponent: React.FC<HomeIndexPageComponentProps> = (props: HomeIndexPageComponentProps) => {
    const {
        givenName,
        displayName,
        hasDevBoxes,
        hasEnvironments,
        organizationDisplayName,
        devBoxUserState,
        environmentUserState,
        devBoxCreateAbilityState,
        environmentCreateAbilityState,
        displayState,
    } = props;

    // Style hooks
    const indexPageStyles = useIndexPageStyles();
    const welcomeMessageContainerStyles = useWelcomeMessageContainerStyles();
    const portalLayoutStyles = usePortalLayoutStyles();
    const portalLayoutWithProgressBarOffsetStyles = usePortalLayoutWithProgressBarOffsetStyles();
    const stackStyles = useStackStyles();
    const headerStyles = useHeaderStyles();
    const centeredHeaderStyles = useCenteredHeaderStyles();

    // Intl hooks
    const { formatMessage } = useIntl();

    const noResourcesDisplayState =
        displayState === DisplayState.EmptyState || displayState === DisplayState.GuestAccount;

    const canWriteDevBoxes = devBoxCreateAbilityState.createAbility === ActionAbility.CanPerformAction;
    const canWriteEnvironments = environmentCreateAbilityState.createAbility === ActionAbility.CanPerformAction;

    // Memoized data
    const addProgressBarOffset =
        devBoxCreateAbilityState.createAbility === ActionAbility.Unknown ||
        environmentCreateAbilityState.createAbility === ActionAbility.Unknown;

    const descriptionText = React.useMemo(() => {
        if (canWriteDevBoxes && canWriteEnvironments && noResourcesDisplayState) {
            return formatMessage(messages.writeBothDevBoxesAndEnvironments);
        }
        if (noResourcesDisplayState) {
            return formatMessage(messages.writeSingleResource);
        }
        return '';
    }, [canWriteDevBoxes, canWriteDevBoxes, noResourcesDisplayState, formatMessage]);

    if (displayState === DisplayState.HasNoProjects) {
        return (
            <CenteredPortalLayout id="index">
                <NoProjects />
            </CenteredPortalLayout>
        );
    }

    if (displayState === DisplayState.GuestAccount && !isFeatureFlagEnabled(FeatureFlagName.EnableGuestUser)) {
        return (
            <CenteredPortalLayout id="index">
                <GuestAccountsNotSupported organizationDisplayName={organizationDisplayName} />
            </CenteredPortalLayout>
        );
    }

    return (
        // HACK: adding this offset so that, when action bar appears on load complete, the content within this component
        // isn't shifted downward.
        <CenteredPortalLayout
            customStyles={addProgressBarOffset ? portalLayoutWithProgressBarOffsetStyles : portalLayoutStyles}
            id="index"
        >
            <div
                className={mergeClasses(
                    stackStyles.root,
                    noResourcesDisplayState ? centeredHeaderStyles.root : headerStyles.root
                )}
            >
                <div className={stackStyles.item}>
                    <h1 className={welcomeMessageContainerStyles.h1}>
                        <FormattedMessage
                            id="IndexPage_WelcomeMessage_Text"
                            defaultMessage="Welcome, {givenName}"
                            description="Welcome message for signed-in user. {givenName} should not be localized."
                            values={{ givenName: givenName ?? displayName }}
                        />
                    </h1>
                </div>
                <div className={stackStyles.item}>
                    <p>{descriptionText}</p>
                </div>
            </div>

            <div className={mergeClasses(stackStyles.root, indexPageStyles.root)}>
                <div className={stackStyles.item}>
                    <HomeIndexPageBody
                        hasDevBoxes={hasDevBoxes}
                        hasEnvironments={hasEnvironments}
                        devBoxUserState={devBoxUserState}
                        environmentUserState={environmentUserState}
                        devBoxCreateAbilityState={devBoxCreateAbilityState}
                        environmentCreateAbilityState={environmentCreateAbilityState}
                        displayState={displayState}
                    />
                </div>
            </div>
        </CenteredPortalLayout>
    );
};

export const HomeIndexPageContainer: React.FC = () => {
    // Application state hooks
    const givenName = useSelector(getUserGivenName);
    const displayName = useSelector(getUserDisplayName);
    const hasDevBoxes = useSelector(getHasDevBoxes);
    const organizationDisplayName = useSelector(getOrganizationDisplayName);
    const hasEnvironments = useSelector(getHasEnvironments);
    const devBoxUserState = useSelector(getDevBoxUserState);
    const environmentUserState = useSelector(getEnvironmentUserState);
    const devBoxCreateAbilityState = useSelector(getDevBoxCreateAbilityState);
    const environmentCreateAbilityState = useSelector(getEnvironmentCreateAbilityState);
    const displayState = useSelector(getDisplayState);

    return (
        <HomeIndexPageComponent
            givenName={givenName}
            displayName={displayName}
            hasDevBoxes={hasDevBoxes}
            hasEnvironments={hasEnvironments}
            organizationDisplayName={organizationDisplayName}
            devBoxUserState={devBoxUserState}
            environmentUserState={environmentUserState}
            devBoxCreateAbilityState={devBoxCreateAbilityState}
            environmentCreateAbilityState={environmentCreateAbilityState}
            displayState={displayState}
        />
    );
};

export default HomeIndexPageContainer;
