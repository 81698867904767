import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../../hooks/action-creator';
import { useUserSettingsPanelContext } from '../../../hooks/context/panels';
import { setUserSettings } from '../../../redux/actions/user-settings/user-settings-action-creator';
import { getUserSettingsSelector } from '../../../redux/selector/user-settings-selector';
import { ReturnVoid } from '../../../types/return-void';
import { UserSettings } from '../../../types/user-settings';
import { FormPanel } from '../../common/form-panel';
import { getUserSettingsPanelInitialValues } from '../selectors';
import { UserSettingsFormFieldGroup } from './user-settings-form-field-group';

type UserSettingsPanelComponentProps = {
    currentUserSettings: UserSettings;
    isOpen: boolean;
    onDismiss: () => void;
    onSubmit: ReturnVoid<typeof setUserSettings>;
};

const UserSettingsPanelComponent: React.FC<UserSettingsPanelComponentProps> = (
    props: UserSettingsPanelComponentProps
) => {
    const { currentUserSettings, isOpen, onDismiss, onSubmit } = props;

    // Memoized data
    const initialValues = React.useMemo(
        () => getUserSettingsPanelInitialValues(currentUserSettings),
        [currentUserSettings]
    );

    return (
        <FormPanel
            isOpen={isOpen}
            onDismiss={onDismiss}
            title={
                <FormattedMessage
                    id="UserSettingsPanel_Header_Text"
                    defaultMessage="User settings"
                    description="Header for the user settings menu"
                />
            }
        >
            <UserSettingsFormFieldGroup currentUserSettings={initialValues} submit={onSubmit} />
        </FormPanel>
    );
};

const UserSettingsPanelContainer: React.FC = () => {
    // Application state hooks
    const currentUserSettings = useSelector(getUserSettingsSelector);

    // Action hooks
    const onSubmit = useActionCreator(setUserSettings);

    // Context hooks
    const { closeSurface: closePanel, isOpen } = useUserSettingsPanelContext();

    return (
        <UserSettingsPanelComponent
            currentUserSettings={currentUserSettings}
            isOpen={isOpen}
            onDismiss={closePanel}
            onSubmit={onSubmit}
        />
    );
};

export const UserSettingsPanelContextWrapper: React.FC = () => {
    const { isOpen } = useUserSettingsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <UserSettingsPanelContainer />;
};

export default UserSettingsPanelContextWrapper;
