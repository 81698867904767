import * as React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { KeepDevBoxDialogProps } from './keep-dev-box-dialog';

export type KeepDevBoxDialogProperties = Omit<KeepDevBoxDialogProps, 'isOpen' | 'onDismiss'>;

export const KeepDevBoxDialogContext = React.createContext<SurfaceContextWithProperties<KeepDevBoxDialogProperties>>({
    isOpen: false,
});

export const KeepDevBoxContextProvider =
    createSurfaceContextWithPropertiesProvider<KeepDevBoxDialogProperties>(KeepDevBoxDialogContext);
