import * as React from 'react';
import ErrorBanner from './error-banner';
import MessageBanner from './message-banner';
import { ResourceCardBannerViewModel, isErrorBannerViewModel, isInfoBannerViewModel } from './models';

interface CardBannerProps {
    cardBanner: ResourceCardBannerViewModel;
}

export const CardBanner: React.FC<CardBannerProps> = React.memo((props: CardBannerProps) => {
    const { cardBanner } = props;

    if (isErrorBannerViewModel(cardBanner)) {
        const {
            error,
            isDismissable,
            additionalErrorBannerActions,
            onRetryFailedOperation,
            onSeeErrorDetails,
            onDismiss,
        } = cardBanner;

        return (
            <ErrorBanner
                onRetry={onRetryFailedOperation}
                onSeeDetails={onSeeErrorDetails}
                retryable={error.retryable}
                severity={error.severity}
                iconProps={error.iconProps}
                additionalActions={additionalErrorBannerActions}
                onDismiss={isDismissable ? onDismiss : undefined}
            >
                {error.message}
            </ErrorBanner>
        );
    }

    if (isInfoBannerViewModel(cardBanner)) {
        const {
            iconProps,
            bannerMessage,
            isDismissable,
            onDismiss,
            onSeeDetails,
            messageBarType,
            additionalMessageBannerActions,
        } = cardBanner;

        return (
            <MessageBanner
                iconProps={iconProps}
                onDismiss={isDismissable ? onDismiss : undefined}
                onSeeDetails={onSeeDetails}
                messageBarType={messageBarType}
                additionalActions={additionalMessageBannerActions}
            >
                {bannerMessage}
            </MessageBanner>
        );
    }

    return <></>;
});
