import { DevBoxAction } from '../../../models/dev-box-action';
import { UnionValueMap } from '../../../types/union-map';
import {
    ActionName,
    ActionType,
    FailedPayload,
    IndexedPayload,
    OptionalResultPayload,
    ResultPayload,
} from '../core-actions';
import {
    delayAllDevBoxActions,
    listDevBoxActions,
    loadDevBoxActionsForDevBoxes,
    skipAllDevBoxActions,
} from './dev-box-action-action-creators';

/**
 * Action types
 */

type DevBoxActionActionName =
    | ActionName<'DelayAllDevBoxActions', 'Error' | 'Failed' | 'Success'>
    | ActionName<'DelayDevBoxAction', 'Failed' | 'Success', false>
    | ActionName<'ListDevBoxActions', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadDevBoxActionsForDevBoxes', 'Error' | 'Failed' | 'Success'>
    | ActionName<'SkipAllDevBoxActions', 'Error' | 'Failed' | 'Success'>
    | ActionName<'SkipDevBoxAction', 'Failed' | 'Success', false>;

export type DevBoxActionActionType =
    | ActionType<'DELAY_ALL_DEV_BOX_ACTIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'DELAY_DEV_BOX_ACTION', '_FAILED' | '_SUCCESS', false>
    | ActionType<'LIST_DEV_BOX_ACTIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_DEV_BOX_ACTIONS_FOR_DEV_BOXES', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'SKIP_ALL_DEV_BOX_ACTIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'SKIP_DEV_BOX_ACTION', '_FAILED' | '_SUCCESS', false>;

export const DevBoxActionActionType: UnionValueMap<DevBoxActionActionName, DevBoxActionActionType> = {
    DelayAllDevBoxActions: 'DELAY_ALL_DEV_BOX_ACTIONS',
    DelayAllDevBoxActionsError: 'DELAY_ALL_DEV_BOX_ACTIONS_ERROR',
    DelayAllDevBoxActionsFailed: 'DELAY_ALL_DEV_BOX_ACTIONS_FAILED',
    DelayAllDevBoxActionsSuccess: 'DELAY_ALL_DEV_BOX_ACTIONS_SUCCESS',
    DelayDevBoxActionFailed: 'DELAY_DEV_BOX_ACTION_FAILED',
    DelayDevBoxActionSuccess: 'DELAY_DEV_BOX_ACTION_SUCCESS',
    ListDevBoxActions: 'LIST_DEV_BOX_ACTIONS',
    ListDevBoxActionsError: 'LIST_DEV_BOX_ACTIONS_ERROR',
    ListDevBoxActionsFailed: 'LIST_DEV_BOX_ACTIONS_FAILED',
    ListDevBoxActionsSuccess: 'LIST_DEV_BOX_ACTIONS_SUCCESS',
    LoadDevBoxActionsForDevBoxes: 'LOAD_DEV_BOX_ACTIONS_FOR_DEV_BOXES',
    LoadDevBoxActionsForDevBoxesError: 'LOAD_DEV_BOX_ACTIONS_FOR_DEV_BOXES_ERROR',
    LoadDevBoxActionsForDevBoxesFailed: 'LOAD_DEV_BOX_ACTIONS_FOR_DEV_BOXES_FAILED',
    LoadDevBoxActionsForDevBoxesSuccess: 'LOAD_DEV_BOX_ACTIONS_FOR_DEV_BOXES_SUCCESS',
    SkipAllDevBoxActions: 'SKIP_ALL_DEV_BOX_ACTIONS',
    SkipAllDevBoxActionsError: 'SKIP_ALL_DEV_BOX_ACTIONS_ERROR',
    SkipAllDevBoxActionsFailed: 'SKIP_ALL_DEV_BOX_ACTIONS_FAILED',
    SkipAllDevBoxActionsSuccess: 'SKIP_ALL_DEV_BOX_ACTIONS_SUCCESS',
    SkipDevBoxActionFailed: 'SKIP_DEV_BOX_ACTION_FAILED',
    SkipDevBoxActionSuccess: 'SKIP_DEV_BOX_ACTION_SUCCESS',
};

/**
 * Payloads
 */

// [TODO]: mflemming - update to use actionType once the API has 'Delete' as an actionType
export interface SkipAllDevBoxActionsPayload extends IndexedPayload {
    within24Hours?: boolean;
    actionName?: string;
}

export interface DelayAllDevBoxActionsPayload extends IndexedPayload {
    delayUntil: Date;
}

export type DelayDevBoxActionFailedPayload = FailedPayload & IndexedPayload;
export type DelayDevBoxActionSuccessPayload = IndexedPayload & OptionalResultPayload<DevBoxAction>;

export type ListDevBoxActionsSuccessPayload = IndexedPayload & ResultPayload<DevBoxAction[]>;

export type SkipDevBoxActionFailedPayload = FailedPayload & IndexedPayload;
export type SkipDevBoxActionSuccessPayload = IndexedPayload;

/**
 * Action shorthands
 */

export type DelayAllDevBoxActionsAction = ReturnType<typeof delayAllDevBoxActions>;
export type ListDevBoxActionsAction = ReturnType<typeof listDevBoxActions>;
export type LoadDevBoxActionsForDevBoxesAction = ReturnType<typeof loadDevBoxActionsForDevBoxes>;
export type SkipAllDevBoxActionsAction = ReturnType<typeof skipAllDevBoxActions>;
