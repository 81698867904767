import { FontSizes, FontWeights, Label } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FeatureFlagName } from '../../../constants/features';
import { RepoSourcedFileContent } from '../../../models/customization';
import { useHorizontalStackStyles, useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import Separator from '../../common/form/separator';
import { CPUMetadata, DiskSizeMetadata, MemoryMetadata } from '../../dev-box/dev-box-metadata/dev-box-metadata';
import { HibernateReadonlyField } from '../../dev-box/hibernate-readonly-field';
import { ScheduleReadonlyField } from '../../dev-box/schedule-readonly-field';
import {
    AddDevBoxFormProjectViewModel,
    CustomizationData,
    ImageViewModel,
    PoolViewModel,
    ScheduleViewModel,
    SizeViewModel,
} from '../models';
import { AddDevBoxSummaryCustomizationsSection } from './add-dev-box-summary-customizations-section';

interface AddDevBoxSummaryProps {
    isSubmitting: boolean;
    name: string;
    project?: AddDevBoxFormProjectViewModel;
    pool?: PoolViewModel;
    image?: ImageViewModel;
    size?: SizeViewModel;
    fileCustomizations?: CustomizationData;
    repoCustomizations?: CustomizationData;
    scheduleForSelectedSize?: ScheduleViewModel;
    isInMicrosoftTenant: boolean;
}

/**
 * Styles
 */

const useLabelStyles = makeStyles({
    root: {
        fontWeight: FontWeights.bold,
    },
});

const useNameStyles = makeStyles({
    root: {
        fontWeight: FontWeights.bold,
        fontSize: FontSizes.size16,
        paddingBottom: '6px',
    },
});

const useProjectStyles = makeStyles({
    root: {
        fontWeight: FontWeights.bold,
        fontSize: FontSizes.size14,
    },
});

const useContainerStyles = makeStyles({
    root: {
        gap: '18px',
    },
});

const useItemStyles = makeStyles({
    root: {
        gap: '2px',
    },
});

const useMetadataStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

const useHibernateAndScheduleContainerStyles = makeStyles({
    root: {
        gap: '5px',
    },
});

/**
 * End Styles
 */

export const AddDevBoxSummary: React.FC<AddDevBoxSummaryProps> = (props) => {
    const {
        project,
        pool,
        image,
        name,
        size,
        fileCustomizations,
        repoCustomizations,
        scheduleForSelectedSize,
        isInMicrosoftTenant,
    } = props;

    // Style hooks
    const labelStyles = useLabelStyles();
    const nameStyles = useNameStyles();
    const projectStyles = useProjectStyles();
    const stackStyles = useStackWithFullWidthItemStyles();
    const containerStyles = useContainerStyles();
    const itemStyles = useItemStyles();
    const metadataStyles = useMetadataStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const hibernateAndScheduleContainerStyles = useHibernateAndScheduleContainerStyles();

    const poolValues = React.useMemo(
        () => ({
            poolName: pool?.name,
            poolRegion: pool?.region,
        }),
        [pool]
    );

    const repoFiles: RepoSourcedFileContent[] = React.useMemo(
        () =>
            repoCustomizations?.contents
                ? repoCustomizations.contents.map((content, index) => {
                      return { name: content, content: repoCustomizations.repoFiles?.[index] ?? '' };
                  })
                : [],
        [repoCustomizations]
    );

    const isRoundTripTimeEnabled = React.useMemo(
        () => isFeatureFlagEnabled(FeatureFlagName.RoundTripTime) || isInMicrosoftTenant,
        [isInMicrosoftTenant]
    );

    if (isRoundTripTimeEnabled) {
        return (
            <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
                <div className={stackStyles.item}>
                    <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                        <div className={stackStyles.item}>
                            <Label styles={labelStyles}>
                                <FormattedMessage
                                    id="AddDevBoxSummary_Name_Title"
                                    defaultMessage="Name"
                                    description="Text for the add dev box summary name title"
                                />
                            </Label>
                        </div>
                        <div className={stackStyles.item}>{name}</div>
                    </div>
                </div>
                <div className={stackStyles.item}>
                    <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                        <div className={stackStyles.item}>
                            <Label styles={labelStyles}>
                                <FormattedMessage
                                    id="AddDevBoxSummary_Project_Title"
                                    defaultMessage="Project"
                                    description="Text for the add dev box summary project title"
                                />
                            </Label>
                        </div>
                        <div className={stackStyles.item}>{project?.name}</div>
                    </div>
                </div>
                <div className={stackStyles.item}>
                    <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                        <div className={stackStyles.item}>
                            <Label styles={labelStyles}>
                                <FormattedMessage
                                    id="AddDevBoxSummary_DevBoxImage_Title"
                                    defaultMessage="Image"
                                    description="Text for the add dev box summary dev box image title"
                                />
                            </Label>
                        </div>
                        <div className={stackStyles.item}>{image?.name}</div>
                    </div>
                </div>
                <div className={stackStyles.item}>
                    <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                        <div className={stackStyles.item}>
                            <Label styles={labelStyles}>
                                <FormattedMessage
                                    id="AddDevBoxSummary_Region_Title"
                                    defaultMessage="Region"
                                    description="Text for the add dev box summary region title"
                                />
                            </Label>
                        </div>
                        <div className={stackStyles.item}>{size?.region}</div>
                    </div>
                </div>
                <div className={stackStyles.item}>
                    <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                        <div className={stackStyles.item}>
                            <Label styles={labelStyles}>
                                <FormattedMessage
                                    id="AddDevBoxSummary_DevBoxSize_Title"
                                    defaultMessage="Size"
                                    description="Text for the add dev box summary dev box size title"
                                />
                            </Label>
                        </div>
                        <div className={stackStyles.item}>
                            <div className={mergeClasses(horizontalStackStyles.root, metadataStyles.root)}>
                                <div className={horizontalStackStyles.item}>
                                    <CPUMetadata cpuCount={size?.cpuCount} />
                                </div>
                                <div className={horizontalStackStyles.item}>
                                    <MemoryMetadata memoryInGb={size?.memoryInGb} />
                                </div>
                                <div className={horizontalStackStyles.item}>
                                    <DiskSizeMetadata diskSizeInGb={size?.diskSizeInGb} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={stackStyles.item}></div>
                <div className={stackStyles.item}>
                    {size && (
                        <div className={mergeClasses(stackStyles.root, hibernateAndScheduleContainerStyles.root)}>
                            <div className={stackStyles.item}>
                                <HibernateReadonlyField
                                    hibernateSupport={size.hibernateSupport}
                                    isInMicrosoftTenant={isInMicrosoftTenant}
                                />
                            </div>
                            {scheduleForSelectedSize && <ScheduleReadonlyField schedule={scheduleForSelectedSize} />}
                        </div>
                    )}
                </div>
                <div className={stackStyles.item}>
                    <Separator />
                </div>
                {(fileCustomizations?.files || repoFiles.length > 0) && (
                    <div className={stackStyles.item}>
                        <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                            <div className={stackStyles.item}>
                                <Label styles={labelStyles}>
                                    <FormattedMessage
                                        id="AddDevBoxSummary_Customizations_Title"
                                        defaultMessage="Customizations and Tasks"
                                        description="Text for the add dev box summary customizations and tasks title"
                                    />
                                </Label>
                            </div>
                            <div className={stackStyles.item}>
                                <AddDevBoxSummaryCustomizationsSection
                                    customizationFiles={fileCustomizations?.files}
                                    repoFiles={repoFiles}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
            <div className={stackStyles.root}>
                <div className={mergeClasses(stackStyles.item, nameStyles.root)}>{name}</div>
                <div className={mergeClasses(stackStyles.item, projectStyles.root)}>{project?.name}</div>
                <div className={stackStyles.item}>
                    <div className={stackStyles.item}>
                        <FormattedMessage
                            id="AddDevBoxSummary_PoolNameAndRegion_Title"
                            defaultMessage="{poolName} ({poolRegion})"
                            description="Text for the add dev box summary pool name and region. Do not localize {poolName} or {poolRegion}"
                            values={poolValues}
                        />
                    </div>
                    <div className={stackStyles.item}>
                        <div className={mergeClasses(horizontalStackStyles.root, metadataStyles.root)}>
                            <div className={horizontalStackStyles.item}>
                                <CPUMetadata cpuCount={pool?.cpuCount} />
                            </div>
                            <div className={horizontalStackStyles.item}>
                                <MemoryMetadata memoryInGb={pool?.memoryInGb} />
                            </div>
                            <div className={horizontalStackStyles.item}>
                                <DiskSizeMetadata diskSizeInGb={pool?.diskSizeInGb} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={stackStyles.item}>
                <Separator />
            </div>
            {(fileCustomizations?.files || repoFiles.length > 0) && (
                <div className={stackStyles.item}>
                    <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                        <div className={stackStyles.item}>
                            <Label styles={labelStyles}>
                                <FormattedMessage
                                    id="AddDevBoxSummary_Customizations_Title"
                                    defaultMessage="Customizations and Tasks"
                                    description="Text for the add dev box summary customizations and tasks title"
                                />
                            </Label>
                        </div>
                        <div className={stackStyles.item}>
                            <AddDevBoxSummaryCustomizationsSection
                                customizationFiles={fileCustomizations?.files}
                                repoFiles={repoFiles}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddDevBoxSummary;
