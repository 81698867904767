import { Link, makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useErrorDetailsPanelContext } from '../../hooks/context/panels';
import { Failure } from '../../models/common';
import { DevBoxRepairOperationResult } from '../../models/dev-box';
import { useStackWithFullWidthItemStyles } from '../../themes/styles/flexbox-styles';
import { isUndefinedOrWhiteSpace } from '../../utilities/string';
import FailureCodeAndMessage from '../common/failure-message/failure-code-and-message';
import { FormPanel } from '../common/form-panel';

export interface ErrorDetailsPanelProps {
    failure?: Failure;
    repairOperationResult?: DevBoxRepairOperationResult;
    devBoxName?: string;
    isOpen: boolean;
    linkHref?: string;
    linkText?: string;
    onDismiss: () => void;
}

/**
 * Styles
 */

const useBodyStyles = makeStyles({
    root: {
        gap: '20px',
    },
});

/**
 * END Styles
 */

const ErrorDetailsPanelComponent: React.FC<ErrorDetailsPanelProps> = (props: ErrorDetailsPanelProps) => {
    const { failure, repairOperationResult, devBoxName, isOpen, linkHref, linkText, onDismiss } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const stackStyles = useStackWithFullWidthItemStyles();
    const bodyStyles = useBodyStyles();

    const titleText = React.useMemo(
        () =>
            repairOperationResult ? (
                <FormattedMessage
                    id="ErrorDetailsPanel_DevBoxNameTitle_Text"
                    defaultMessage="{devBoxName} connection status"
                    description='Title of "Error details" panel with dev box name. {devBoxName} should not be localized.'
                    values={{ devBoxName: devBoxName }}
                />
            ) : (
                <FormattedMessage
                    id="ErrorDetailsPanel_Title_Text"
                    defaultMessage="Error details"
                    description='Title of the "Error details" panel'
                />
            ),
        [repairOperationResult, devBoxName, formatMessage]
    );

    return (
        <FormPanel isOpen={isOpen} onDismiss={onDismiss} title={titleText}>
            <div className={mergeClasses(stackStyles.root, bodyStyles.root)}>
                <div className={stackStyles.item}>
                    <FailureCodeAndMessage failure={failure} repairOperationResult={repairOperationResult} />
                </div>

                {!isUndefinedOrWhiteSpace(linkHref) && (
                    <div className={stackStyles.item}>
                        <Link href={linkHref} target="_blank">
                            {!isUndefinedOrWhiteSpace(linkText) ? linkText : linkHref}
                        </Link>
                    </div>
                )}
            </div>
        </FormPanel>
    );
};

const ErrorDetailsPanelContainer: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useErrorDetailsPanelContext();

    return <ErrorDetailsPanelComponent isOpen={isOpen} onDismiss={closePanel} {...properties} />;
};

export const ErrorDetailsPanelContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen } = useErrorDetailsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <ErrorDetailsPanelContainer />;
};

export default ErrorDetailsPanelContextWrapper;
