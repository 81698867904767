import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Divider,
    FluentProvider,
    makeStyles,
    mergeClasses,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useForceDeleteDialogContext } from '../../../hooks/context/dialogs';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { Checkbox } from '../../common/form/checkbox';

export interface ForceDeleteDialogComponentProps {
    open?: boolean;
    onDismiss: () => void;
    onPrimaryButtonSubmitted: () => void;
    primaryButtonAriaLabel?: string;
    primaryButtonText: string;
    subText: string;
    title: string;
}

/**
 * Styles
 */

const useBodyStyles = makeStyles({
    root: {
        gap: '20px',
        paddingBottom: '12px',
    },
});

const messages = defineMessages({
    cancelButtonAriaLabel: {
        id: 'ForceDeleteDialog_CancelButton_AriaLabel',
        defaultMessage: 'Cancel the "delete" operation',
        description: 'Aria label for the cancel button to dismiss the dialog and perform no operation',
    },
    cancelButtonText: {
        id: 'ForceDeleteDialog_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Cancel button to dismiss the delete dialog and perform no operation',
    },
    closeButtonAriaLabel: {
        id: 'ForceDeleteDialog_CloseButton_AriaLabel',
        defaultMessage: 'Dismiss the "Delete" dialog',
        description: "Aria label for the delete dialog's close button",
    },
    forceDeleteDialogCheckboxText: {
        id: 'EnvironmentCard_ForceDeleteDialogCheckboxText_Text',
        defaultMessage: 'Yes, I want to force delete this environment',
        description: 'Confirmation checkbox for force deleting the environment.',
    },
});

const ForceDeleteDialog: React.FC<ForceDeleteDialogComponentProps> = (props: ForceDeleteDialogComponentProps) => {
    const { open, onDismiss, onPrimaryButtonSubmitted, primaryButtonAriaLabel, primaryButtonText, subText, title } =
        props;

    //Style Hook
    const bodyStyles = useBodyStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    // Intl hooks
    const { formatMessage } = useIntl();

    // Callback hooks
    const onPrimaryButtonClicked = React.useCallback(() => {
        onPrimaryButtonSubmitted();
        onDismiss();
    }, [onDismiss, onPrimaryButtonSubmitted]);

    const [forceEnabled, setForceEnabled] = React.useState(false);

    const onChangeForceEnabled = React.useCallback((checked) => {
        setForceEnabled(checked);
    }, []);

    return (
        <FluentProvider theme={theme}>
            <Dialog modalType="alert" open={open} onOpenChange={onDismiss}>
                <DialogSurface>
                    <DialogBody id="ForceDeleteDialog">
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={formatMessage(messages.closeButtonAriaLabel)}
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            }
                        >
                            {title}
                        </DialogTitle>

                        <DialogContent>
                            <div className={mergeClasses(stackStyles.root, bodyStyles.root)}>
                                {subText}

                                <Divider />
                                <Checkbox
                                    label={formatMessage(messages.closeButtonAriaLabel)}
                                    checkboxLabel={formatMessage(messages.forceDeleteDialogCheckboxText)}
                                    checkboxAriaLabel={formatMessage(messages.closeButtonAriaLabel)}
                                    onChangeHandler={onChangeForceEnabled}
                                    value={forceEnabled}
                                />
                            </div>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                appearance="primary"
                                aria-label={primaryButtonAriaLabel}
                                onClick={onPrimaryButtonClicked}
                                disabled={!forceEnabled}
                            >
                                {primaryButtonText}
                            </Button>
                            <DialogTrigger>
                                <Button
                                    appearance="secondary"
                                    aria-label={formatMessage(messages.cancelButtonAriaLabel)}
                                >
                                    <FormattedMessage
                                        id="ForceDeleteDialog_CancelButton_Text"
                                        defaultMessage="Cancel"
                                        description="Cancel button to dismiss the delete dialog and perform no operation"
                                    />
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </FluentProvider>
    );
};

export const ForceDeleteDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useForceDeleteDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <ForceDeleteDialog {...properties} open={isOpen} onDismiss={closeSurface} />;
};

export default ForceDeleteDialogContextWrapper;
