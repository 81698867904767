import { CommonSkipDelayDevBoxActionErrorCode } from '../../data/contracts/dev-box-action';
import { CustomizationFailure, CustomizationGroup } from '../../models/customization';
import { DevBox, DevBoxRepairOperation, DevBoxSnapshot, FailureOnDevBox } from '../../models/dev-box';
import { DevBoxAction } from '../../models/dev-box-action';
import { Pool } from '../../models/pool';
import { AsyncState } from '../../redux/store/common-state';
import { DevBoxState } from '../../redux/store/dev-box-state';
import { RequiredProperty } from '../../types/required-property';
import { UnionMap } from '../../types/union-map';

export type DevBoxActionWithNextScheduledTime = RequiredProperty<DevBoxAction, 'next'>;

export const isDevBoxActionWithNextScheduledTime = (
    devBoxAction: DevBoxAction
): devBoxAction is DevBoxActionWithNextScheduledTime => !!devBoxAction.next;

export interface DevBoxActionViewModel extends Pick<DevBoxAction, 'name' | 'sourceId' | 'actionType'> {
    nextScheduledTime: Date;
    originalScheduledTime?: Date;
}

export type DevBoxActionViewModelErrorCode = CommonSkipDelayDevBoxActionErrorCode | 'GenericError';

export const DevBoxActionViewModelErrorCode: UnionMap<DevBoxActionViewModelErrorCode> = {
    ...CommonSkipDelayDevBoxActionErrorCode,
    GenericError: 'GenericError',
};

export interface DevBoxActionState {
    delayState: AsyncState;
    skipState: AsyncState;
    delayErrorCode?: DevBoxActionViewModelErrorCode;
    skipErrorCode?: DevBoxActionViewModelErrorCode;
    nextScheduledStopTime?: Date;
    originalScheduledStopTime?: Date;
    scheduledDeleteTime?: Date;
}

export interface DevBoxViewModel {
    key: string;
    resource: DevBox;
    state: DevBoxState;
    failureOnDevBox?: FailureOnDevBox;
    remoteDesktopUrl?: string;
    webUrl?: string;
    isCardContentReady: boolean;
    devBoxActionState: DevBoxActionState;
    customizationGroup?: CustomizationGroup;
    customizationGroups?: CustomizationGroup[];
    repairOperation?: DevBoxRepairOperation;
    customizationFailure?: CustomizationFailure;
    pool?: Pool;
    projectDisplayName?: string;
    cloudPcConnectionUrl?: string;
    snapshots?: DevBoxSnapshot[];
}
