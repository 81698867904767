import { ListDevBoxActionsResponse } from '../../../data/services/data-plane-api/dev-box-action';
import { AggregatedResult } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import {
    DelayAllDevBoxActionsPayload,
    DelayDevBoxActionFailedPayload,
    DelayDevBoxActionSuccessPayload,
    DevBoxActionActionType,
    ListDevBoxActionsSuccessPayload,
    SkipAllDevBoxActionsPayload,
    SkipDevBoxActionFailedPayload,
    SkipDevBoxActionSuccessPayload,
} from './dev-box-action-actions';

export const delayAllDevBoxActions = createActionCreator<DelayAllDevBoxActionsPayload>(
    DevBoxActionActionType.DelayAllDevBoxActions,
    { startsActivity: true }
);

export const delayAllDevBoxActionsError = createActionCreator<ErrorPayload & IndexedPayload>(
    DevBoxActionActionType.DelayAllDevBoxActionsError
);

export const delayAllDevBoxActionsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    DevBoxActionActionType.DelayAllDevBoxActionsFailed
);

export const delayAllDevBoxActionsSuccess = createActionCreator<IndexedPayload>(
    DevBoxActionActionType.DelayAllDevBoxActionsSuccess
);

export const delayDevBoxActionFailed = createActionCreator<DelayDevBoxActionFailedPayload>(
    DevBoxActionActionType.DelayDevBoxActionFailed,
    { groupable: true }
);

export const delayDevBoxActionSuccess = createActionCreator<DelayDevBoxActionSuccessPayload>(
    DevBoxActionActionType.DelayDevBoxActionSuccess,
    { groupable: true }
);

export const listDevBoxActions = createActionCreator<IndexedPayload, ListDevBoxActionsResponse>(
    DevBoxActionActionType.ListDevBoxActions,
    { async: true, startsActivity: true }
);

export const listDevBoxActionsError = createActionCreator<ErrorPayload & IndexedPayload>(
    DevBoxActionActionType.ListDevBoxActionsError
);

export const listDevBoxActionsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    DevBoxActionActionType.ListDevBoxActionsFailed
);

export const listDevBoxActionsSuccess = createActionCreator<ListDevBoxActionsSuccessPayload>(
    DevBoxActionActionType.ListDevBoxActionsSuccess
);

export const loadDevBoxActionsForDevBoxes = createActionCreatorWithoutPayload<AggregatedResult>(
    DevBoxActionActionType.LoadDevBoxActionsForDevBoxes,
    { async: true }
);

export const loadDevBoxActionsForDevBoxesError = createActionCreator<ErrorPayload>(
    DevBoxActionActionType.LoadDevBoxActionsForDevBoxesError
);

export const loadDevBoxActionsForDevBoxesFailed = createActionCreator<FailedPayload>(
    DevBoxActionActionType.LoadDevBoxActionsForDevBoxesFailed
);

export const loadDevBoxActionsForDevBoxesSuccess = createActionCreatorWithoutPayload(
    DevBoxActionActionType.LoadDevBoxActionsForDevBoxesSuccess
);

export const skipAllDevBoxActions = createActionCreator<SkipAllDevBoxActionsPayload>(
    DevBoxActionActionType.SkipAllDevBoxActions,
    {
        startsActivity: true,
    }
);

export const skipAllDevBoxActionsError = createActionCreator<ErrorPayload & IndexedPayload>(
    DevBoxActionActionType.SkipAllDevBoxActionsError
);

export const skipAllDevBoxActionsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    DevBoxActionActionType.SkipAllDevBoxActionsFailed
);

export const skipAllDevBoxActionsSuccess = createActionCreator<IndexedPayload>(
    DevBoxActionActionType.SkipAllDevBoxActionsSuccess
);

export const skipDevBoxActionFailed = createActionCreator<SkipDevBoxActionFailedPayload>(
    DevBoxActionActionType.SkipDevBoxActionFailed,
    { groupable: true }
);

export const skipDevBoxActionSuccess = createActionCreator<SkipDevBoxActionSuccessPayload>(
    DevBoxActionActionType.SkipDevBoxActionSuccess,
    { groupable: true }
);
