import { DrawerBody } from '@fluentui/react-components';
import * as React from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../hooks/action-creator';
import { useDevMenuPanelContext } from '../../hooks/context/panels';
import { FeatureFlagSet } from '../../models/features';
import { setFeatures } from '../../redux/actions/features/features-action-creators';
import { getEnabledFeatures, getFrozenFeatures } from '../../redux/selector/features-selectors';
import { ReturnVoid } from '../../types/return-void';
import { FormPanel } from '../common/form-panel';
import { DevMenuFormFieldGroup } from './dev-menu-form-field-group';
import { DevMenuPanelFooter } from './dev-menu-panel-footer';
import { DevMenuFormData } from './models';
import { getDevMenuPanelInitialValues, getFeatureFlagSetFromFormData } from './selectors';

interface DevMenuPanelComponentProps {
    enabledFeatures: FeatureFlagSet;
    frozenFeatures: FeatureFlagSet;
    isOpen: boolean;
    onDismiss: () => void;
    onSubmit: ReturnVoid<typeof setFeatures>;
}

const DevMenuPanelComponent: React.FC<DevMenuPanelComponentProps> = (props: DevMenuPanelComponentProps) => {
    const { enabledFeatures, frozenFeatures, isOpen, onDismiss, onSubmit } = props;

    // Callback hooks
    const onSubmitClicked = React.useCallback(
        (data: DevMenuFormData) => {
            onDismiss();

            const features = getFeatureFlagSetFromFormData(data);
            onSubmit({ features });
        },
        [onDismiss, onSubmit]
    );

    // Memoized data
    const initialValues = React.useMemo(
        () => getDevMenuPanelInitialValues(enabledFeatures, frozenFeatures),
        [enabledFeatures, frozenFeatures]
    );

    return (
        <Form<DevMenuFormData> initialValues={initialValues} onSubmit={onSubmitClicked}>
            {(formProps) => {
                const { form, pristine, valid } = formProps;
                const { reset, submit } = form;

                // Callback hooks
                const onPanelDismissed = React.useCallback(() => {
                    onDismiss();
                    reset();
                }, [onDismiss, reset]);

                return (
                    <FormPanel
                        isOpen={isOpen}
                        onDismiss={onPanelDismissed}
                        title={
                            <FormattedMessage
                                id="DevMenuPanel_Header_Text"
                                defaultMessage="Feature flags"
                                description="Header for the dev/feature flags menu"
                            />
                        }
                        hasBodyWithFooter
                    >
                        <DrawerBody>
                            <DevMenuFormFieldGroup />
                        </DrawerBody>
                        <DevMenuPanelFooter
                            isSubmitDisabled={pristine || !valid}
                            onCancelClicked={onPanelDismissed}
                            onSubmitClicked={submit}
                        />
                    </FormPanel>
                );
            }}
        </Form>
    );
};

const DevMenuPanelContainer: React.FC = () => {
    // Application state hooks
    const enabledFeatures = useSelector(getEnabledFeatures);
    const frozenFeatures = useSelector(getFrozenFeatures);

    // Action hooks
    const onSubmit = useActionCreator(setFeatures);

    // Context hooks
    const { closeSurface: closePanel, isOpen } = useDevMenuPanelContext();

    return (
        <DevMenuPanelComponent
            enabledFeatures={enabledFeatures}
            frozenFeatures={frozenFeatures}
            isOpen={isOpen}
            onDismiss={closePanel}
            onSubmit={onSubmit}
        />
    );
};

export const DevMenuPanelContextWrapper: React.FC = () => {
    const { isOpen } = useDevMenuPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <DevMenuPanelContainer />;
};

export default DevMenuPanelContextWrapper;
