import { makeStyles, typographyStyles } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { FeatureFlagName } from '../../../constants/features';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import InProgressOperationMessage from '../../common/in-progress-operation-message';
import { DevBoxActionState } from '../models';
import { DevBoxCardInfoBannerMessageKind } from './models';
import { getNumberDaysUntilScheduledDelete } from './selectors';

interface DevBoxBannerInfoMessageProps {
    kind: DevBoxCardInfoBannerMessageKind;
    ariaLabel?: string;
    devBoxActionState: DevBoxActionState;
}

/**
 * Styles
 */

const boldTextStyles = makeStyles({
    root: typographyStyles.caption1Strong,
});

/**
 * END Styles
 */

export const DevBoxBannerInfoMessage: React.FC<DevBoxBannerInfoMessageProps> = (
    props: DevBoxBannerInfoMessageProps
) => {
    const { kind, ariaLabel, devBoxActionState } = props;

    const useBoldTextStyles = boldTextStyles();

    const offloadMessageValues = React.useMemo(
        () => ({
            daysUntilDeletion: getNumberDaysUntilScheduledDelete(devBoxActionState),
        }),
        [devBoxActionState]
    );

    switch (kind) {
        case DevBoxCardInfoBannerMessageKind.RepairOperationInProgressMessage:
            return (
                <InProgressOperationMessage ariaLabel={ariaLabel}>
                    <FormattedMessage
                        id="DevBoxCard_RepairOperationInfo_Text"
                        defaultMessage="Troubleshooting connection. This takes 20 minutes on average, but may take up to 40 minutes."
                        description="In progress notification for repair operation"
                    />
                </InProgressOperationMessage>
            );
        case DevBoxCardInfoBannerMessageKind.RestoreOperationInProgressMessage:
            return (
                <InProgressOperationMessage ariaLabel={ariaLabel}>
                    <FormattedMessage
                        id="DevBoxCard_RestoreOperationInfo_Text"
                        defaultMessage="Restoring dev box"
                        description="In progress notification for restore operation"
                    />
                </InProgressOperationMessage>
            );
        case DevBoxCardInfoBannerMessageKind.NoIssuesDetectedMessage:
            return (
                <FormattedMessage
                    id="DevBoxCard_RepairOperationNoIssuesDetected_Text"
                    defaultMessage="No issues detected."
                    description="No issues detected notification after repair operation"
                />
            );
        case DevBoxCardInfoBannerMessageKind.CustomizeOperationInProgressMessage:
            return (
                <InProgressOperationMessage ariaLabel={ariaLabel}>
                    <FormattedMessage
                        id="DevBoxCard_CustomizeOperationInfo_Text"
                        defaultMessage="Creating a Dev Box with your customizations. This may take a while."
                        description="In progress notification for customization operation"
                    />
                </InProgressOperationMessage>
            );
        case DevBoxCardInfoBannerMessageKind.OffloadDevBoxMessage:
            return (
                <>
                    {isFeatureFlagEnabled(FeatureFlagName.EnableOffloadDevBox) && (
                        <>
                            <div className={useBoldTextStyles.root}>
                                {offloadMessageValues.daysUntilDeletion !== undefined &&
                                offloadMessageValues.daysUntilDeletion > 0 ? (
                                    <FormattedMessage
                                        id="DevBoxCard_OffloadDevBoxMessage_Text"
                                        defaultMessage="Deletion pending in {daysUntilDeletion} {daysUntilDeletion, plural, one {day} other {days}}"
                                        description="Notification for devbox which has pending deletion"
                                        values={offloadMessageValues}
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="DevBoxCard_OffloadDevBoxMessage_UndefinedDays_Text"
                                        defaultMessage="Deletion pending"
                                        description="Notification for devbox which the pending deletion"
                                    />
                                )}
                            </div>
                            <FormattedMessage
                                id="DevBoxCard_OffloadDevBoxMessage_AdditionalText"
                                defaultMessage="Your administrator has set this dev box to be deleted. Please contact them if this dev box is still needed."
                                description="Additional details for the pending deletion notification"
                            />
                        </>
                    )}
                </>
            );
        default:
            return <></>;
    }
};

export default DevBoxBannerInfoMessage;
