import {
    Button,
    Card,
    CardHeader,
    Divider,
    FluentProvider,
    Link,
    makeStyles,
    mergeClasses,
    tokens,
    typographyStyles,
} from '@fluentui/react-components';
import { Copy20Regular, Home16Regular, Lightbulb16Regular, Remote16Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { OperatingSystemFamily } from '../../constants/browser';
import { Language, SupportedMarket } from '../../constants/localization';
import { SupportSectionLinks } from '../../constants/support-section-links';
import { Metric, Property } from '../../constants/telemetry';
import { WindowsAppLink } from '../../constants/windows-store-links';
import { useConfigureConnectionDialogContext } from '../../hooks/context/dialogs';
import { useHelpMenuPanelContext } from '../../hooks/context/panels';
import { useCurrentFluent2Theme } from '../../hooks/styling';
import { getLocalizationConfiguration } from '../../language/languages';
import messages from '../../language/messages';
import { getIsUserSignedIntoMicrosoftTenant } from '../../redux/selector/tenant-selector';
import { getRemoteAppOptionsSelector } from '../../redux/selector/user-settings-selector';
import {
    useCenteredHorizontalStackStyles,
    useHorizontalStackStyles,
    useStackStyles,
} from '../../themes/styles/flexbox-styles';
import { RemoteAppOption } from '../../types/user-settings';
import { getOperatingSystemFamily } from '../../utilities/browser';
import { getSessionID, trackMetric } from '../../utilities/telemetry/channel';
import { AriaLiveWrapper } from '../common/accessibility/aria-live-wrapper';
import { FormPanel } from '../common/form-panel';

interface HelpMenuPanelProps {
    currentLanguage: Language;
    currentMarket: string;
    isOpen: boolean;
    onDismiss: () => void;
}

interface PrivacyAndTermsProps {
    currentLanguage: Language;
    currentMarket: string;
}

const helpMenuMessages = defineMessages({
    helpMenuPanelTitle: {
        id: 'HelpMenuPanel_Title',
        defaultMessage: 'Help & feedback',
        description: 'Aria label for help menu panel title',
    },
    helpMenuPanelCloseButtonLabel: {
        id: 'HelpMenuPanel_Close_Label',
        defaultMessage: 'Close help panel',
        description: 'Aria label for the help menu panel close button label',
    },
    helpMenuPanelFeedbackAriaLabel: {
        id: 'HelpMenuPanel_Feedback_Submenu_AriaLabel',
        defaultMessage: 'Have a feature suggestion?',
        description: 'Aria label for the help menu panel feedback submenu title',
    },
    helpMenuPanelSupportAriaLabel: {
        id: 'HelpMenuPanel_Support_Submenu_AriaLabel',
        defaultMessage: 'Need Support?',
        description: 'Title for the help menu panel support submenu title',
    },
    helpMenuInternalAriaLabel: {
        id: 'HelpMenuPanel_Internal_AriaLabel',
        defaultMessage: 'Microsoft internal',
        description: 'Title for the Microsoft internal help panel submenu',
    },
    copyButtonAriaLabel: {
        id: 'DevBoxHelpMenuPanel_CopyButton_AriaLabel',
        defaultMessage: 'Copy issue ID',
        description: 'Aria label for the "Copy" button in the "Dev Box Support" panel',
    },
});

const handleTroubleshootLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelTroubleShootLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.TroubleshootAndRepair,
        },
    });
};

const handleInternalSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelInternalSupportLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.InternalSupportLink,
        },
    });
};

const handleExternalSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelExternalSupportLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.ExternalSupportLink,
        },
    });
};

const handleDevHomeSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelDevHomeLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.DevHomeLink,
        },
    });
};

const handleDevBoxFeedbackSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelDevBoxFeedbackLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.DevBoxFeedback,
        },
    });
};

const handleAdeFeedbackSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelAdeFeedbackLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.AdeFeedback,
        },
    });
};

const handlePrivacySupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelPrivacyLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.Privacy,
        },
    });
};

const handleProductTermsSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelProductTermsLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.ProductTerms,
        },
    });
};

const handleOneESDevBoxFaqLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelOneESDevBoxFaqLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.OneESDevBoxFaq,
        },
    });
};

const handleWindowsAppLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelWindowsAppLinkClicked, 1, {
        properties: {
            [Property.Link]: WindowsAppLink,
        },
    });
};

const getFormattedMessageValues = (href: string, onClick: () => void, target = '_blank') => {
    const values = {
        Link: (chunks: string) => (
            <Link href={href} target={target} onClick={onClick}>
                {chunks}
            </Link>
        ),
    };
    return values;
};

const internalSupportLinkValues = getFormattedMessageValues(
    SupportSectionLinks.InternalSupportLink,
    handleInternalSupportLinkClick
);
const externalSupportLinkValues = getFormattedMessageValues(
    SupportSectionLinks.ExternalSupportLink,
    handleExternalSupportLinkClick
);

/**
 * Styles
 */

const useNeedHelpSectionStyles = makeStyles({
    root: {
        boxShadow: '0px 3.2px 7.2px rgba(0, 0, 0, 0.132), 0px 0.6px 1.8px rgba(0, 0, 0, 0.108);',
        borderRadius: '2px',
        padding: '22px',
        border: 'none',
        marginBottom: '15px',
        userSelect: 'text',
        backgroundColor: tokens.colorNeutralBackground2,
    },
    documentCardLink: {
        lineHeight: '20px',
        paddingTop: '13px',
        gap: '12px',
    },
    copyButton: {
        margin: '8px',
    },
});

const usePrivacyTermsOfUseStyles = makeStyles({
    root: {
        ...typographyStyles.caption1,
        paddingTop: '15px',
        lineHeight: '16px',
        justifyContent: 'center',
        gap: '4px',
    },
});

const useIconStyles = makeStyles({
    root: {
        gap: '8px',
    },
    icon: {
        paddingTop: '3px',
    },
});

/**
 * End Styles
 */

const NeedHelpSection: React.FC = () => {
    const { openSurface: onOpenConfigureConnectionDialog } = useConfigureConnectionDialogContext();

    const userRemoteAppOption = useSelector(getRemoteAppOptionsSelector);

    // Intl hooks
    const intl = useIntl();

    // Style hooks
    const needHelpSectionStyles = useNeedHelpSectionStyles();
    const stackStyles = useStackStyles();
    const iconStyles = useIconStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    const isWindows = getOperatingSystemFamily() === OperatingSystemFamily.Windows;

    const isAvdPreference = userRemoteAppOption === RemoteAppOption.useAvd;

    return (
        <AriaLiveWrapper>
            <Card
                aria-label={intl.formatMessage(helpMenuMessages.helpMenuPanelTitle)}
                className={needHelpSectionStyles.root}
            >
                <CardHeader
                    description={
                        <FormattedMessage
                            id="HelpMenuPanel_NeedHelp_Submenu_Title"
                            defaultMessage="Connect to a dev box"
                            description="Title for the help menu panel to connect to a dev box"
                        />
                    }
                />

                <div className={mergeClasses(stackStyles.root, needHelpSectionStyles.documentCardLink)}>
                    {isWindows && !isAvdPreference ? (
                        <div className={stackStyles.item}>
                            <Link href={WindowsAppLink} target="_blank" onClick={handleWindowsAppLinkClick}>
                                <div className={mergeClasses(horizontalStackStyles.root, iconStyles.root)}>
                                    <div className={mergeClasses(horizontalStackStyles.item, iconStyles.icon)}>
                                        <Remote16Regular />
                                    </div>
                                    <FormattedMessage
                                        id="HelpMenuPanel_NeedHelp_DownloadWindowsAppLink"
                                        defaultMessage="Download Windows App"
                                        description="Link to download windows app."
                                    />
                                </div>
                            </Link>
                        </div>
                    ) : (
                        <div className={stackStyles.item}>
                            <Link onClick={onOpenConfigureConnectionDialog}>
                                <div className={mergeClasses(horizontalStackStyles.root, iconStyles.root)}>
                                    <div className={mergeClasses(horizontalStackStyles.item, iconStyles.icon)}>
                                        <Remote16Regular />
                                    </div>
                                    <FormattedMessage
                                        id="HelpMenuPanel_NeedHelp_ConfigureRemoteDesktopLink"
                                        defaultMessage="Configure Remote Desktop"
                                        description="Link to opening the Configure Remote Desktop Modal."
                                    />
                                </div>
                            </Link>
                        </div>
                    )}
                    <div className={stackStyles.item}>
                        <Link
                            href={SupportSectionLinks.DevHomeLink}
                            target="_blank"
                            onClick={handleDevHomeSupportLinkClick}
                        >
                            <div className={mergeClasses(horizontalStackStyles.root, iconStyles.root)}>
                                <div className={mergeClasses(horizontalStackStyles.item, iconStyles.icon)}>
                                    <Home16Regular />
                                </div>
                                <FormattedMessage
                                    id="HelpMenuPanel_NeedHelp_DevHomeLink"
                                    defaultMessage="Use Dev Home to manage dev boxes"
                                    description="Link to getting dev home information."
                                />
                            </div>
                        </Link>
                    </div>
                </div>
            </Card>
        </AriaLiveWrapper>
    );
};

interface SupportSectionProps {
    isInMicrosoftTenant: boolean;
}

const SupportSection: React.FC<SupportSectionProps> = (props: SupportSectionProps) => {
    const { isInMicrosoftTenant } = props;

    // Intl hooks
    const intl = useIntl();
    const { formatMessage } = intl;

    // Style hooks
    const needHelpSectionStyles = useNeedHelpSectionStyles();
    const stackStyles = useStackStyles();

    // We do this within the FC because session ID isn't stable / set when the scripts first load.
    const sessionID = React.useMemo(() => getSessionID(), []);

    const theme = useCurrentFluent2Theme();

    // Callback hooks
    const onCopyClickedHandler = React.useCallback(() => {
        navigator.clipboard.writeText(sessionID);
    }, [sessionID]);

    return (
        <Card
            aria-label={formatMessage(helpMenuMessages.helpMenuPanelSupportAriaLabel)}
            className={needHelpSectionStyles.root}
        >
            <CardHeader
                description={
                    <FormattedMessage
                        id="HelpMenuPanel_Support_Submenu_Title"
                        defaultMessage="Need Support?"
                        description="Title for the help menu panel support submenu title"
                    />
                }
            />
            <div className={mergeClasses(stackStyles.root, needHelpSectionStyles.documentCardLink)}>
                <div className={stackStyles.item}>
                    <Link
                        href={SupportSectionLinks.TroubleshootAndRepair}
                        onClick={handleTroubleshootLinkClick}
                        target="_blank"
                    >
                        <FormattedMessage
                            id="HelpMenuPanel_Support_DevBoxTroubleshootLink"
                            defaultMessage="Troubleshoot your dev box"
                            description="Link to troubleshoot devbox and get more information."
                        />
                    </Link>
                </div>
                <div className={stackStyles.item}>
                    <FormattedMessage
                        id="HelpMenuPanel_Support_DevBoxTroubleshootText"
                        defaultMessage="If you're unable to resolve the issue, contact your admin and provide the issue ID"
                        description="Directions to troubleshoot devbox and get more information."
                    />
                </div>
                <div className={mergeClasses(stackStyles.root, needHelpSectionStyles.copyButton)}>
                    <FluentProvider theme={theme}>
                        <Button
                            aria-label={formatMessage(helpMenuMessages.copyButtonAriaLabel)}
                            onClick={onCopyClickedHandler}
                            icon={<Copy20Regular />}
                        >
                            <FormattedMessage
                                id="DevBoxHelpMenuPanel_CopyButton_Text"
                                defaultMessage="Copy issue ID"
                                description='Text for the "Copy" button in the "Dev Box Help Menu" panel'
                            />
                        </Button>
                    </FluentProvider>
                </div>
                {isInMicrosoftTenant ? (
                    <div className={stackStyles.item}>
                        <FormattedMessage
                            id="HelpMenuPanel_Support_InternalSupportLink"
                            defaultMessage="If the issue persists and you cannot create or connect to a dev box, <Link>get support on TechWeb</Link>"
                            description="Link to get support for internal customers. <Link> and </Link> should not be localized, but text between them should."
                            values={internalSupportLinkValues}
                        />
                    </div>
                ) : (
                    <div className={stackStyles.item}>
                        <FormattedMessage
                            id="HelpMenuPanel_Support_ExternalSupportLink"
                            defaultMessage="If the issue persists and you cannot create or connect to a dev box,<Link>contact Azure help + support</Link>"
                            description="Link to get support for external customers. <Link> and </Link> should not be localized, but text between them should."
                            values={externalSupportLinkValues}
                        />
                    </div>
                )}
            </div>
        </Card>
    );
};

const FeedbackSection: React.FC = () => {
    // Intl hooks
    const intl = useIntl();

    // Style
    const needHelpSectionStyles = useNeedHelpSectionStyles();
    const stackStyles = useStackStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const iconStyles = useIconStyles();

    return (
        <Card
            aria-label={intl.formatMessage(helpMenuMessages.helpMenuPanelFeedbackAriaLabel)}
            className={needHelpSectionStyles.root}
        >
            <CardHeader
                description={
                    <FormattedMessage
                        id="HelpMenuPanel_Feedback_Submenu_Title"
                        defaultMessage="Have a feature suggestion?"
                        description="Aria label for the help menu panel feedback submenu title"
                    />
                }
            />
            <div className={mergeClasses(stackStyles.root, needHelpSectionStyles.documentCardLink)}>
                <div className={stackStyles.item}>
                    <Link
                        href={SupportSectionLinks.DevBoxFeedback}
                        target="_blank"
                        onClick={handleDevBoxFeedbackSupportLinkClick}
                    >
                        <div className={mergeClasses(horizontalStackStyles.root, iconStyles.root)}>
                            <div className={mergeClasses(horizontalStackStyles.item, iconStyles.icon)}>
                                <Lightbulb16Regular />
                            </div>
                            <FormattedMessage
                                id="HelpMenuPanel_Feedback_DevBoxFeedbackLink"
                                defaultMessage="Suggest a Dev Box feature"
                                description="Link to suggest a Dev Box feature."
                            />
                        </div>
                    </Link>
                </div>
                <div className={stackStyles.item}>
                    <Link
                        href={SupportSectionLinks.AdeFeedback}
                        target="_blank"
                        onClick={handleAdeFeedbackSupportLinkClick}
                    >
                        <div className={mergeClasses(horizontalStackStyles.root, iconStyles.root)}>
                            <div className={mergeClasses(horizontalStackStyles.item, iconStyles.icon)}>
                                <Lightbulb16Regular />
                            </div>
                            <FormattedMessage
                                id="HelpMenuPanel_Feedback_AdeFeedbackLink"
                                defaultMessage="Suggest a Deployment Environments feature"
                                description="Link to suggest a ADE feature."
                            />
                        </div>
                    </Link>
                </div>
            </div>
        </Card>
    );
};

const PrivacyAndTerms: React.FC<PrivacyAndTermsProps> = (props: PrivacyAndTermsProps) => {
    const { currentLanguage, currentMarket } = props;

    // Style
    const classNames = usePrivacyTermsOfUseStyles();
    const horizontalStackStyles = useCenteredHorizontalStackStyles();

    // For Korean language and market, we have to a different and very specific string for this link.
    // More info here: https://liquid.microsoft.com/Web/Object/Read/MS.Privacy.MPS/Requirements/03.03.01.03
    const privacyLinkMessageDescriptor =
        currentLanguage === Language.KO || currentMarket === SupportedMarket.KR
            ? messages.privacyKoreanLanguageLinkText
            : messages.privacyLinkText;

    return (
        <div className={mergeClasses(horizontalStackStyles.root, classNames.root)}>
            <div className={horizontalStackStyles.item}>
                <Link href={SupportSectionLinks.Privacy} target="_blank" onClick={handlePrivacySupportLinkClick}>
                    <FormattedMessage {...privacyLinkMessageDescriptor} />
                </Link>
            </div>
            <div className={horizontalStackStyles.item}>
                <Divider vertical />
            </div>
            <div className={horizontalStackStyles.item}>
                <Link
                    href={SupportSectionLinks.ProductTerms}
                    target="_blank"
                    onClick={handleProductTermsSupportLinkClick}
                >
                    <FormattedMessage {...messages.productTermsLinkText} />
                </Link>
            </div>
        </div>
    );
};

const InternalSection: React.FC = () => {
    // Intl hooks
    const { formatMessage } = useIntl();

    // Style
    const needHelpSectionStyles = useNeedHelpSectionStyles();
    const stackStyles = useStackStyles();

    return (
        <Card
            aria-label={formatMessage(helpMenuMessages.helpMenuInternalAriaLabel)}
            className={needHelpSectionStyles.root}
        >
            <CardHeader
                description={
                    <FormattedMessage
                        id="HelpMenuPanel_Internal_Title"
                        defaultMessage="Microsoft internal"
                        description="Title for the Microsoft internal help panel submenu"
                    />
                }
            />
            <div className={mergeClasses(stackStyles.root, needHelpSectionStyles.documentCardLink)}>
                <div className={stackStyles.item}>
                    <Link
                        href={SupportSectionLinks.OneESDevBoxFaq}
                        target="_blank"
                        onClick={handleOneESDevBoxFaqLinkClick}
                    >
                        <FormattedMessage
                            id="HelpMenuPanel_OneESDevBoxFaq_Link"
                            defaultMessage="1ES Dev Box FAQ"
                            description="Link to 1ES internal FAQ page."
                        />
                    </Link>
                </div>
            </div>
        </Card>
    );
};

export const HelpMenuPanelComponent: React.FC<HelpMenuPanelProps> = (props: HelpMenuPanelProps) => {
    const { currentLanguage, currentMarket, isOpen, onDismiss } = props;

    // Intl hooks
    const intl = useIntl();

    // Style hooks

    // Selector hooks
    const isInMicrosoftTenant = useSelector(getIsUserSignedIntoMicrosoftTenant);

    return (
        <FormPanel
            isOpen={isOpen}
            onDismiss={onDismiss}
            closeButtonAriaLabel={intl.formatMessage(helpMenuMessages.helpMenuPanelCloseButtonLabel)}
            title={
                <FormattedMessage
                    id="HelpMenuPanel_Title"
                    defaultMessage="Help & feedback"
                    description="Aria label for help menu panel title"
                />
            }
            isMediumSize
        >
            <NeedHelpSection />
            <SupportSection isInMicrosoftTenant={isInMicrosoftTenant} />
            {isInMicrosoftTenant && <InternalSection />}
            <FeedbackSection />
            <PrivacyAndTerms currentLanguage={currentLanguage} currentMarket={currentMarket} />
        </FormPanel>
    );
};

export const HelpMenuPanelContextWrapper: React.FC = () => {
    const { language, market } = getLocalizationConfiguration();

    // Context hooks
    const { closeSurface: closePanel, isOpen } = useHelpMenuPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return (
        <HelpMenuPanelComponent
            currentLanguage={language}
            currentMarket={market}
            isOpen={isOpen}
            onDismiss={closePanel}
        />
    );
};

export default HelpMenuPanelContextWrapper;
