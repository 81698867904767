import { IIconProps } from '@fluentui/react';
import { MenuItem } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { ClientDownloadLink } from '../../../constants/avd';
import { OperatingSystemFamily } from '../../../constants/browser';
import { sharedMessages } from './messages';

type RemoteDesktopDownloadMenuItemProps = React.PropsWithChildren<{
    ariaLabel: string;
    onSelected: () => void;
}>;

export const RemoteDesktopDownloadMenuItem: React.FC<RemoteDesktopDownloadMenuItemProps> = (props) => {
    const { ariaLabel, children, onSelected } = props;

    const onClick = React.useCallback(() => {
        onSelected();
    }, [onSelected]);

    return (
        <MenuItem aria-label={ariaLabel} onClick={onClick}>
            {children}
        </MenuItem>
    );
};

export const getDefaultDownloadLink = (operatingSystemFamily: OperatingSystemFamily): string => {
    switch (operatingSystemFamily) {
        case OperatingSystemFamily.Android:
            return ClientDownloadLink.Android;
        case OperatingSystemFamily.IOS:
            return ClientDownloadLink.IOS;
        case OperatingSystemFamily.MacOS:
            return ClientDownloadLink.Mac;
        case OperatingSystemFamily.Windows:
        default:
            return ClientDownloadLink.WindowsX64;
    }
};

export const getIconPropsForDownloadPrimaryButton = (
    operatingSystemFamily: OperatingSystemFamily
): IIconProps | undefined => {
    // No icon is used when the OS family is Windows
    if (operatingSystemFamily === OperatingSystemFamily.Windows) {
        return undefined;
    }

    return {
        iconName: 'OpenInNewTab',
    };
};

export const getMessageDescriptorForDownloadPrimaryButton = (
    operatingSystemFamily: OperatingSystemFamily
): JSX.Element => {
    switch (operatingSystemFamily) {
        case OperatingSystemFamily.Windows:
            return (
                <FormattedMessage
                    id="DownloadRemoteDesktopButton_WindowsPrimaryButton_Text"
                    defaultMessage="Download 64 bit"
                    description="Button text for primary part of split button when on a Windows platform"
                />
            );
        default:
            return (
                <FormattedMessage
                    id="DownloadRemoteDesktopButton_NonWindowsPrimaryButton_Text"
                    defaultMessage="Go to app store"
                    description="Button text for primary part of split button when on a non-Windows platform"
                />
            );
    }
};

export const getMessageDescriptorForDownloadPrimaryButtonAriaLabel = (
    operatingSystemFamily: OperatingSystemFamily
): MessageDescriptor => {
    switch (operatingSystemFamily) {
        case OperatingSystemFamily.Windows:
            return sharedMessages.windowsPrimaryButtonAriaLabel;
        default:
            return sharedMessages.nonWindowsPrimaryButtonAriaLabel;
    }
};

export const getMessageDescriptorForTitle = (operatingSystemFamily: OperatingSystemFamily): MessageDescriptor => {
    switch (operatingSystemFamily) {
        case OperatingSystemFamily.Android:
            return sharedMessages.androidRemoteDesktopTitle;
        case OperatingSystemFamily.IOS:
            return sharedMessages.iOSRemoteDesktopTitle;
        case OperatingSystemFamily.MacOS:
            return sharedMessages.macRemoteDesktopTitle;
        case OperatingSystemFamily.Windows:
        default:
            return sharedMessages.windowsRemoteDesktopTitle;
    }
};
