import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { FeatureFlagName } from '../../../../constants/features';
import { PerformanceMetric } from '../../../../constants/telemetry';
import {
    AggregatedFailure,
    AggregatedResult,
    ClientError,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
    isClientError,
} from '../../../../models/common';
import { combineResults, getErrorCodes } from '../../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../../utilities/failure';
import { isFeatureFlagEnabled } from '../../../../utilities/features';
import { trackTimedPerformanceMetric } from '../../../../utilities/telemetry/channel';
import { loadDevBoxRegionRecommendations } from '../../../actions/dev-box-region-recommendation/dev-box-region-recommendation-action-creators';
import {
    loadAddDevBoxFormContent,
    loadAddDevBoxFormContentError,
    loadAddDevBoxFormContentFailed,
    loadAddDevBoxFormContentSuccess,
} from '../../../actions/sub-applications/home/home-action-creators';
import { LoadAddDevBoxFormContentAction } from '../../../actions/sub-applications/home/home-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { rejectAction } from '../../../effects/reject-action';
import { resolveAction } from '../../../effects/resolve-action';
import { takeLeading } from '../../../effects/take';
import { getIsUserSignedIntoMicrosoftTenant } from '../../../selector/tenant-selector';
import { AsyncOutcome } from '../../../store/common-state';
import { tryPerformDiscoverPoolsForProjects } from '../../pool/discover-pools-for-projects';
import { tryPerformDiscoverSchedulesForPools } from '../../schedule/discover-schedules-for-pools';

function* terminateWithFailure(
    action: LoadAddDevBoxFormContentAction,
    aggregatedFailure: AggregatedFailure,
    startTime: Date
): SagaIterator {
    const { failures } = aggregatedFailure;
    const failure = aggregateFailureResponses(FailureOperation.LoadAddDevBoxFormContent, ...failures);
    yield put(loadAddDevBoxFormContentFailed({ failure }));
    yield resolveAction(action, aggregatedFailure);
    yield call(
        trackTimedPerformanceMetric,
        PerformanceMetric.LoadAddDevBoxFormContent,
        startTime,
        AsyncOutcome.Failed,
        { errorCodes: getErrorCodes(aggregatedFailure) }
    );
}

export function* loadAddDevBoxFormContentSaga(action: LoadAddDevBoxFormContentAction): SagaIterator {
    const startTime = new Date();

    try {
        const poolsResult: AggregatedResult = yield call(tryPerformDiscoverPoolsForProjects);

        if (isAggregatedFailure(poolsResult)) {
            yield call(terminateWithFailure, action, poolsResult, startTime);
            return;
        }

        const schedulesResult: AggregatedResult | ClientError = yield call(tryPerformDiscoverSchedulesForPools);

        // If schedules failed to load, we can still load form content, so we don't need to fail form discovery
        const discoverSchedulesFailed = isClientError(schedulesResult) || isAggregatedFailure(schedulesResult);
        const completeResult = discoverSchedulesFailed ? poolsResult : combineResults([poolsResult, schedulesResult]);

        // Load region recommendation data
        const isInMicrosoftTenant = yield select(getIsUserSignedIntoMicrosoftTenant);
        const isRoundTripTimeFeatureEnabled = yield call(isFeatureFlagEnabled, FeatureFlagName.RoundTripTime);

        if (isRoundTripTimeFeatureEnabled || isInMicrosoftTenant) {
            yield putAndAwait(loadDevBoxRegionRecommendations());
        }

        yield put(loadAddDevBoxFormContentSuccess());
        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadAddDevBoxFormContent,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadAddDevBoxFormContent);
        yield put(loadAddDevBoxFormContentError({ error }));
        yield rejectAction(action, error);
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadAddDevBoxFormContent,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* loadAddDevBoxFormContentListenerSaga(): SagaIterator {
    yield takeLeading(loadAddDevBoxFormContent, loadAddDevBoxFormContentSaga);
}
