import { SkipDevBoxActionErrorCode } from '../../../../data/contracts/dev-box-action';
import { FailureResponse } from '../../../../models/common';

export const getSkipErrorCode = (response: FailureResponse): SkipDevBoxActionErrorCode | string => {
    const code = response.statusCode;

    switch (code) {
        case 204:
            return SkipDevBoxActionErrorCode.DevBoxActionAlreadySkipped;
        case 404:
            return SkipDevBoxActionErrorCode.DevBoxActionNotFound;
        default:
            return response.code;
    }
};
