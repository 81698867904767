import React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { ForceDeleteDialogComponentProps } from './force-delete-dialog';

export type ForceDeleteDialogProperties = Omit<ForceDeleteDialogComponentProps, 'hidden' | 'onDismiss'>;

export const ForceDeleteDialogContext = React.createContext<SurfaceContextWithProperties<ForceDeleteDialogProperties>>({
    isOpen: false,
});

export const ForceDeleteDialogContextProvider =
    createSurfaceContextWithPropertiesProvider<ForceDeleteDialogProperties>(ForceDeleteDialogContext);
