import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    FluentProvider,
    makeStyles,
    mergeClasses,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useKeepDevBoxDialogContext } from '../../../hooks/context/dialogs';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';

export interface KeepDevBoxDialogProps {
    onDismiss: () => void;
    onSkipScheduledDelete: () => void;
    open?: boolean;
}

const messages = defineMessages({
    keepDevBoxButtonAriaLabel: {
        id: 'KeepDevBoxDialog_KeepDevBoxButton_AriaLabel',
        defaultMessage: 'Keep',
        description: 'Aria label for the submit button on the keep dev box dialog',
    },
    cancelButtonAriaLabel: {
        id: 'KeepDevBoxDialog_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for the cancel button on the keep dev box dialog.',
    },
    closeButtonAriaLabel: {
        id: 'KeepDevBoxDialog_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for the "x" button in the top corner of the keep dev box dialog',
    },
});

/**
 * Styles
 */
const dialogWidth = '569px';
const dialogStyle: React.CSSProperties = {
    width: dialogWidth,
};

const useDialogStackStyles = makeStyles({
    root: {
        gap: '18px',
        padding: '0px 0px 30px 0px',
    },
});

/**
 * End Styles
 */

const KeepDevBoxDialog: React.FC<KeepDevBoxDialogProps> = (props: KeepDevBoxDialogProps) => {
    const { onDismiss, onSkipScheduledDelete, open } = props;

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const stackStyles = useStackStyles();
    const dialogStackStyles = useDialogStackStyles();

    const onSubmit = React.useCallback(() => {
        onSkipScheduledDelete();
        onDismiss();
    }, [onSkipScheduledDelete, onDismiss]);

    return (
        <FluentProvider theme={theme}>
            <Dialog modalType="alert" open={open} onOpenChange={onDismiss}>
                <DialogSurface style={dialogStyle}>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={formatMessage(messages.closeButtonAriaLabel)}
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            }
                        >
                            <FormattedMessage
                                id="KeepDevBoxDialog_Title_KeepDevBox"
                                defaultMessage="Keep this dev box"
                                description="The title for the dialog describing the skip scheduled delete action."
                            />
                        </DialogTitle>
                        <DialogContent>
                            <div className={mergeClasses(stackStyles.root, dialogStackStyles.root)}>
                                <div className={stackStyles.item}>
                                    <FormattedMessage
                                        id="KeepDevBoxDialog_Description_Text"
                                        defaultMessage="This dev box will be retained, and your admin will be notified."
                                        description="Keep dev box dialog description."
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                appearance="primary"
                                aria-label={formatMessage(messages.keepDevBoxButtonAriaLabel)}
                                onClick={onSubmit}
                            >
                                <FormattedMessage
                                    id="KeepDevBox_Button_Label"
                                    defaultMessage="Keep"
                                    description="Label for the submit button on the keep dev box dialog."
                                />
                            </Button>
                            <DialogTrigger>
                                <Button
                                    appearance="secondary"
                                    aria-label={formatMessage(messages.cancelButtonAriaLabel)}
                                >
                                    <FormattedMessage
                                        id="KeepDevBox_CancelButton_Label"
                                        defaultMessage="Cancel"
                                        description="Label for the cancel button on the keep dev box dialog."
                                    />
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </FluentProvider>
    );
};

export const KeepDevBoxDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useKeepDevBoxDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <KeepDevBoxDialog {...properties} open={isOpen} onDismiss={closeSurface} />;
};
