import {
    Divider,
    Menu,
    MenuButtonProps,
    MenuList,
    MenuPopover,
    MenuTrigger,
    SplitButton,
} from '@fluentui/react-components';
import { Open20Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { ClientDownloadLink } from '../../../constants/avd';
import { openInNewWindow, openInSameWindow, operatingSystemFamily } from '../../../utilities/browser';
import {
    getDefaultDownloadLink,
    getMessageDescriptorForDownloadPrimaryButton,
    getMessageDescriptorForDownloadPrimaryButtonAriaLabel,
    RemoteDesktopDownloadMenuItem,
} from './selectors';

const messages = defineMessages({
    downloadAndroidOptionAriaLabel: {
        id: 'DownloadRemoteDesktopButton_DownloadAndroidOption_AriaLabel',
        defaultMessage: 'Remote Desktop for Android',
        description: 'Aria label for dropdown button option to download a client for Android',
    },
    downloadIOSOptionAriaLabel: {
        id: 'DownloadRemoteDesktopButton_DownloadIOSOption_AriaLabel',
        defaultMessage: 'Remote Desktop for iOS',
        description: 'Aria label for dropdown button option to download a client for iOS',
    },
    downloadMacOptionAriaLabel: {
        id: 'DownloadRemoteDesktopButton_DownloadMacOption_AriaLabel',
        defaultMessage: 'Remote Desktop for Mac',
        description: 'Aria label for dropdown button option to download a client for Mac',
    },
    downloadWindowsARM64OptionAriaLabel: {
        id: 'DownloadRemoteDesktopButton_DownloadWindowsARM64Option_AriaLabel',
        defaultMessage: 'Download Windows ARM 64',
        description: 'Aria label for dropdown button option to download a client for Windows ARM 64',
    },
    downloadWindowsX64OptionAriaLabel: {
        id: 'DownloadRemoteDesktopButton_DownloadWindowsX64Option_AriaLabel',
        defaultMessage: 'Download Windows 64 bit',
        description: 'Aria label for dropdown button option to download a client for Windows 64 bit (x64)',
    },
    downloadWindowsX86OptionAriaLabel: {
        id: 'DownloadRemoteDesktopButton_DownloadWindowsX86Option_AriaLabel',
        defaultMessage: 'Download Windows 32 bit',
        description: 'Aria label for dropdown button option to download a client for Windows 32 bit (x86)',
    },
    splitButtonAriaLabel: {
        id: 'DownloadRemoteDesktopButton_SplitButton_AriaLabel',
        defaultMessage: 'See more options',
        description: 'Aria label for dropdown button in open split button for dev box cards',
    },
});

// Windows client download links are opened in same window because they're direct file downloads
const onDownloadWindowsARM64Clicked = (): void => openInSameWindow(ClientDownloadLink.WindowsARM64);
const onDownloadWindowsX64Clicked = (): void => openInSameWindow(ClientDownloadLink.WindowsX64);
const onDownloadWindowsX86Clicked = (): void => openInSameWindow(ClientDownloadLink.WindowsX86);

// Non-windows download links are opened in separate windows because they render a different page
const onDownloadAndroidClicked = (): void => openInNewWindow(ClientDownloadLink.Android);
const onDownloadIOSClicked = (): void => openInNewWindow(ClientDownloadLink.IOS);
const onDownloadMacClicked = (): void => openInNewWindow(ClientDownloadLink.Mac);

export const DownloadRemoteDesktopButton: React.FC = () => {
    // Intl hooks
    const { formatMessage } = useIntl();

    // Memoized data
    const defaultDownloadLink = React.useMemo(
        () => getDefaultDownloadLink(operatingSystemFamily),
        [operatingSystemFamily]
    );

    const menuList: JSX.Element[] = React.useMemo<JSX.Element[]>(() => {
        const androidOption = (
            <RemoteDesktopDownloadMenuItem
                ariaLabel={formatMessage(messages.downloadAndroidOptionAriaLabel)}
                key="download-android"
                onSelected={onDownloadAndroidClicked}
            >
                <FormattedMessage
                    id="DownloadRemoteDesktopButton_DownloadAndroidOption_Text"
                    defaultMessage="Remote Desktop for Android"
                    description="Text for dropdown button option to download a client for Android"
                />
            </RemoteDesktopDownloadMenuItem>
        );

        const iOSOption = (
            <RemoteDesktopDownloadMenuItem
                ariaLabel={formatMessage(messages.downloadIOSOptionAriaLabel)}
                key="download-ios"
                onSelected={onDownloadIOSClicked}
            >
                <FormattedMessage
                    id="DownloadRemoteDesktopButton_DownloadIOSOption_Text"
                    defaultMessage="Remote Desktop for iOS"
                    description="Text for dropdown button option to download a client for iOS"
                />
            </RemoteDesktopDownloadMenuItem>
        );

        const macOption = (
            <RemoteDesktopDownloadMenuItem
                ariaLabel={formatMessage(messages.downloadMacOptionAriaLabel)}
                key="download-mac"
                onSelected={onDownloadMacClicked}
            >
                <FormattedMessage
                    id="DownloadRemoteDesktopButton_DownloadMacOption_Text"
                    defaultMessage="Remote Desktop for Mac"
                    description="Text for dropdown button option to download a client for Mac"
                />
            </RemoteDesktopDownloadMenuItem>
        );

        const windowsARM64Option = (
            <RemoteDesktopDownloadMenuItem
                ariaLabel={formatMessage(messages.downloadWindowsARM64OptionAriaLabel)}
                key="download-windows-arm64"
                onSelected={onDownloadWindowsARM64Clicked}
            >
                <FormattedMessage
                    id="DownloadRemoteDesktopButton_DownloadWindowsARM64Option_Text"
                    defaultMessage="Download Windows ARM 64"
                    description="Text for dropdown button option to download a client for Windows ARM 64"
                />
            </RemoteDesktopDownloadMenuItem>
        );

        const windowsX64Option = (
            <RemoteDesktopDownloadMenuItem
                ariaLabel={formatMessage(messages.downloadWindowsX64OptionAriaLabel)}
                key="download-windows-x64"
                onSelected={onDownloadWindowsX64Clicked}
            >
                <FormattedMessage
                    id="DownloadRemoteDesktopButton_DownloadWindowsX64Option_Text"
                    defaultMessage="Download Windows 64 bit"
                    description="Text for dropdown button option to download a client for Windows 64 bit (x64)"
                />
            </RemoteDesktopDownloadMenuItem>
        );

        const windowsX86Option = (
            <RemoteDesktopDownloadMenuItem
                ariaLabel={formatMessage(messages.downloadWindowsX86OptionAriaLabel)}
                key="download-windows-x86"
                onSelected={onDownloadWindowsX86Clicked}
            >
                <FormattedMessage
                    id="DownloadRemoteDesktopButton_DownloadWindowsX86Option_Text"
                    defaultMessage="Download Windows 32 bit"
                    description="Text for dropdown button option to download a client for Windows 32 bit (x86)"
                />
            </RemoteDesktopDownloadMenuItem>
        );

        return [
            windowsX64Option,
            windowsX86Option,
            windowsARM64Option,
            <Divider key="divider" />,
            macOption,
            iOSOption,
            androidOption,
        ];
    }, [formatMessage]);

    const menuButtonAriaLabelMessageDescriptor = React.useMemo(
        () => getMessageDescriptorForDownloadPrimaryButtonAriaLabel(operatingSystemFamily),
        [operatingSystemFamily]
    );

    const menuButtonFormattedMessage = React.useMemo(
        () => getMessageDescriptorForDownloadPrimaryButton(operatingSystemFamily),
        [operatingSystemFamily]
    );

    // Callback hooks
    const onPrimaryButtonClicked = React.useCallback(() => {
        openInSameWindow(defaultDownloadLink);
    }, [defaultDownloadLink]);

    const primaryActionButtonProps = React.useMemo(() => {
        return {
            onClick: onPrimaryButtonClicked,
        };
    }, [onPrimaryButtonClicked]);

    const menuButtonProps = React.useMemo(() => {
        return {
            ariaLabel: formatMessage(messages.splitButtonAriaLabel),
        };
    }, [formatMessage]);

    return (
        <Menu>
            <MenuTrigger>
                {(triggerProps: MenuButtonProps) => (
                    <SplitButton
                        icon={<Open20Regular />}
                        aria-label={formatMessage(menuButtonAriaLabelMessageDescriptor)}
                        primaryActionButton={primaryActionButtonProps}
                        menuButton={{ ...triggerProps, ...menuButtonProps }}
                    >
                        {menuButtonFormattedMessage}
                    </SplitButton>
                )}
            </MenuTrigger>

            <MenuPopover>
                <MenuList>{menuList}</MenuList>
            </MenuPopover>
        </Menu>
    );
};
