import {
    Button,
    Card,
    CardHeader,
    FluentProvider,
    makeStyles,
    tokens,
    typographyStyles,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import remoteDesktopIcon from '../../../content/images/Icon_Azure-Virtual-Desktop-Store.svg';
import WindowsAppIcon from '../../../content/images/Icon_WindowsApp.svg';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { getRemoteAppOptionsSelector } from '../../../redux/selector/user-settings-selector';
import { RemoteAppOption } from '../../../types/user-settings';

interface ConnectionCardProps {
    onActionInvoked: () => void;
    onDismiss?: () => void;
}

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'QuickActionCard_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for quick action card "Close" button',
    },
});

/**
 * Styles
 */

const useCardStyles = makeStyles({
    root: {
        width: '305px',
    },
    icon: {
        height: '32px',
    },
    title: typographyStyles.body1Strong,
    content: {
        ...typographyStyles.caption1,
        color: tokens.colorNeutralForeground3,
    },
});

/**
 * END Styles
 */

export const ConfigureConnectionCard: React.FC<ConnectionCardProps> = React.memo((props: ConnectionCardProps) => {
    const { onActionInvoked, onDismiss } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const theme = useCurrentFluent2Theme();

    const userRemoteAppOption = useSelector(getRemoteAppOptionsSelector);
    const isUsingRemoteDesktop = userRemoteAppOption === RemoteAppOption.useAvd;

    // Style hooks
    const cardStyles = useCardStyles();

    // Callback hooks
    const onDismissHandler = React.useCallback(
        (
            event: React.MouseEvent<
                HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement,
                MouseEvent
            >
        ) => {
            if (onDismiss) {
                // Block event from propagating up to the card's click handler so that you don't also open the dialog when you attempt to dismiss the card
                event.stopPropagation();

                onDismiss();
            }
        },
        [onDismiss]
    );

    if (isUsingRemoteDesktop) {
        return (
            <FluentProvider theme={theme}>
                <Card onClick={onActionInvoked} focusMode="off" className={cardStyles.root}>
                    <CardHeader
                        image={<img src={remoteDesktopIcon} className={cardStyles.icon} alt="" />}
                        header={
                            <div className={cardStyles.title}>
                                <FormattedMessage
                                    id="DevBoxes_ConfigureRemoteDesktopActionTitle_Text"
                                    defaultMessage="Configure Remote Desktop"
                                    description="Title of the 'Configure Remote Desktop' quick action card"
                                />
                            </div>
                        }
                        action={
                            <Button
                                appearance="subtle"
                                aria-label={formatMessage(messages.closeButtonAriaLabel)}
                                icon={<Dismiss24Regular />}
                                onClick={onDismissHandler}
                            />
                        }
                    />
                    <div className={cardStyles.content}>
                        <FormattedMessage
                            id="DevBoxes_ConfigureRemoteDesktopActionSubtitle_Text"
                            defaultMessage="Download and set up Remote Desktop for Android to access dev boxes on your device."
                            description="Explanatory text for the 'Configure Remote Desktop' quick action card"
                        />
                    </div>
                </Card>
            </FluentProvider>
        );
    }

    return (
        <FluentProvider theme={theme}>
            <Card onClick={onActionInvoked} focusMode="off" className={cardStyles.root}>
                <CardHeader
                    image={<img src={WindowsAppIcon} className={cardStyles.icon} alt="" />}
                    header={
                        <div className={cardStyles.title}>
                            <FormattedMessage
                                id="DevBoxes_ConfigureWindowsAppActionTitle_Text"
                                defaultMessage="Configure Windows App"
                                description="Title of the 'Configure Windows App' quick action card"
                            />
                        </div>
                    }
                    action={
                        <Button
                            appearance="subtle"
                            aria-label={formatMessage(messages.closeButtonAriaLabel)}
                            icon={<Dismiss24Regular />}
                            onClick={onDismissHandler}
                        />
                    }
                />
                <div className={cardStyles.content}>
                    <FormattedMessage
                        id="DevBoxes_ConfigureWindowsAppActionSubtitle_Text"
                        defaultMessage="Download and set up Windows App to access dev boxes on your Mac device."
                        description="Explanatory text for the 'Configure Windows App' quick action card"
                    />
                </div>
            </Card>
        </FluentProvider>
    );
});

export default ConfigureConnectionCard;
